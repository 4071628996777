import React, { useState } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";

const Accordion = ({ data }) => {
    const [openItems, setOpenItems] = useState({});

    const toggleItem = (index) => {
        setOpenItems((prevOpenItems) => ({
            ...prevOpenItems,
            [index]: !prevOpenItems[index],
        }));
    };

    const renderItems = (items, parentIndex = "") => {
        return items.map((item, index) => {
            const currentIndex = parentIndex
                ? `${parentIndex}-${index}`
                : `${index}`;
            const isOpen = openItems[currentIndex];

            return (
                <div
                    key={currentIndex}
                    className="accordion-item px-1 py-1 bg-[#b3e0ea]"
                >
                    <div
                        className="accordion-header flex items-center justify-between text-xs cursor-pointer"
                        onClick={() => toggleItem(currentIndex)}
                    >
                        {item.title}
                        {parentIndex === "" ? (
                            isOpen ? (
                                <CiSquareMinus className="text-sm" />
                            ) : (
                                <CiSquarePlus className="text-sm font-bold text-black" />
                            )
                        ) : (
                            <span
                                className="cursor-pointer"
                                onClick={() => toggleItem(currentIndex)}
                            >
                                X
                            </span>
                        )}
                    </div>
                    <div
                        className={`accordion-content overflow-hidden ${
                            isOpen
                                ? "max-h-screen transition-max-h duration-500"
                                : "max-h-0"
                        }`}
                    >
                        {isOpen && item.children && (
                            <div className="my-2">
                                {renderItems(item.children, currentIndex)}
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    };

    return <div className="accordion  text-black">{renderItems(data)}</div>;
};

export default Accordion;
