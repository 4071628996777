import React from "react";
import ChangePassword from "./../../pages/changePassword/ChangePassword";

const NewUserDetails = () => {
    return (
        <div className="w-full">
            <div className="bg-white md:m-2 md:p-2">
                {/* user search section */}
                <div className="px-2 my-4">
                    <div className="text-[#16b0c8] text-[16px] font-bold my-1 ">
                        User Details
                    </div>
                    <input
                        type="text"
                        placeholder="Search by client"
                        className="border-2 p-[px] pl-2 rounded-md md:w-[15rem] w-full"
                    />
                </div>

                {/* conditional rendering section */}
                <div className="w-full">
                    <div className=" w-full grid grid-cols-1 lg:grid-cols-2    ">
                        <div className="relative p-1 md:p-4">
                            {/* user details */}
                            <div className="flex items-center my-[-10px]">
                                <div className="border-t border-slate-300 pl-4 "></div>
                                <div className=" text-[14px] font-bold bg-white px-2 ml-[0px] ">
                                    UserDetails:
                                </div>
                                <div className="flex-grow border-t border-slate-300 "></div>
                            </div>
                            <div className="border-l border-r border-b border-slate-300 rounded-b p-1 md:p-4 bg-slate-">
                                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 p-[0.2rem] ">
                                    {/* User Name : */}
                                    <div className="flex items-center gap-1 md:gap-11 font-[500]">
                                        <div className="text-xs text-slate-400  whitespace-nowrap">
                                            User Name :
                                        </div>
                                        <div className="text-[12px] ">
                                            radheuser99
                                        </div>
                                    </div>
                                    {/*  Reference Name : */}
                                    <div className="flex items-center gap-1 md:gap-2 font-[500]">
                                        <div className="text-xs text-slate-400  whitespace-nowrap">
                                            Reference Name :
                                        </div>
                                        <div className="text-[12px]">XYZ</div>
                                    </div>
                                    {/* Parents :*/}
                                    <div className="flex items-center gap-2 md:gap-16 font-[500]">
                                        <div className="text-xs text-slate-400  whitespace-nowrap">
                                            Parents :
                                        </div>
                                        <div className="text-[12px] ">
                                            admin99radhe
                                        </div>
                                    </div>

                                    {/* Role : */}
                                    <div className="flex items-center gap-2 md:gap-8 font-[500]">
                                        <div className=" text-xs text-slate-400  whitespace-nowrap">
                                            Role :
                                        </div>
                                        <div className="text-[12px]">
                                            Client
                                        </div>
                                    </div>
                                    {/* Email : */}
                                    <div className="flex items-center gap-1 md:gap-6 font-[500]">
                                        <div className="text-xs text-slate-400  whitespace-nowrap">
                                            Email :
                                        </div>
                                        <div className="text-[12px]">
                                            XYZ123@gmail.com
                                        </div>
                                    </div>

                                    {/* Client Name  : */}
                                    <div className="flex items-center  gap-1 md:gap-2 font-[500]">
                                        <div className="text-xs text-slate-400  whitespace-nowrap">
                                            Client Name :
                                        </div>
                                        <div className="text-[12px]">
                                            radheuser99
                                        </div>
                                    </div>
                                    {/*   Mobile : */}
                                    <div className="flex items-center gap-2 md:gap-[2.8rem] font-[500]">
                                        <div className="text-xs text-slate-400  whitespace-nowrap">
                                            Mobile :
                                        </div>
                                        <div className="text-[12px] ">
                                            0123456789
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*setting */}
                        <div className="relative p-1 md:p-4 my-2">
                            {/* Setting: */}
                            <div className="flex items-center my-[-10px]">
                                <div className="border-t border-slate-300 pl-4 "></div>
                                <div className=" text-[14px] font-bold bg-white px-2 ml-[0px] ">
                                    Setting:
                                </div>
                                <div className="flex-grow border-t border-slate-300 "></div>
                            </div>
                            <div className="border-l border-r border-b border-slate-300 rounded-b md:p-4">
                                <div className="grid ">
                                    <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 md:gap-2  my-2">
                                        <button className=" flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[10px] text-center md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-2 mx-1  mt-3 md:mt-0 rounded-full md:rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            User Update
                                        </button>
                                        <button className="flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[8px] md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-2 mx-1  mt-3 md:mt-0 rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            Deposit/Credit
                                        </button>
                                        <button className=" flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[8px] md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-1 mx-1  mt-3 md:mt-0 rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            Last Login
                                        </button>
                                        <button className="flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[8px] md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-1 mx-1  mt-3 md:mt-0 rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            Settlement
                                        </button>
                                        <button className="flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[8px] md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-1 mx-1  mt-3 md:mt-0 rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            CG Debit/Credit
                                        </button>
                                        <button className=" flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[10px] text-center md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-2 mx-1  mt-3 md:mt-0 rounded-full md:rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            Change Password
                                        </button>
                                        <button className=" flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[10px] text-center md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-2 mx-1  mt-3 md:mt-0 rounded-full md:rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            Withdrawal
                                        </button>
                                        <button className=" flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[10px] text-center md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-2 mx-1  mt-3 md:mt-0 rounded-full md:rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            Game Control
                                        </button>
                                        <button className=" flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[10px] text-center md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-2 mx-1  mt-3 md:mt-0 rounded-full md:rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            Casino Control
                                        </button>
                                        <button className=" flex items-center justify-center bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-[10px] text-center md:text-xs lg:text-[10px] text-white font-medium md:font-bold py-2 md:py-2 md:px-8 px-2 mx-1  mt-3 md:mt-0 rounded-full md:rounded-full bg-button cursor-pointer whitespace-nowrap">
                                            CG Withdrawal
                                        </button>

                                        {/* <div className="grid grid-cols-5 px-4 text-xs font-bold"> */}
                                        <div className="flex items-center gap-2 text-xs font-semibold px-2 mt-3">
                                            <input
                                                type="checkbox"
                                                className="w-[15px] h-[15px]"
                                            />
                                            <div>User Lock</div>
                                        </div>
                                        <div className="flex items-center gap-2 text-xs font-semibold px-2 mt-3">
                                            <input
                                                type="checkbox"
                                                className="w-[15px] h-[15px]"
                                            />
                                            <div>Bet Lock</div>
                                        </div>
                                        <div className="flex items-center gap-2 text-xs font-semibold px-2 mt-3">
                                            <input
                                                type="checkbox"
                                                className="w-[15px] h-[15px]"
                                            />
                                            <div>Check Limit</div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Account Details: */}
                    <div className="">
                        <div className="relative p-1 md:p-4 my-4">
                            {/* Account Details: */}
                            <div className="flex items-center my-[-10px]">
                                <div className="border-t border-slate-300 pl-4 "></div>
                                <div className=" text-[14px] font-bold bg-white px-2 ml-[0px] ">
                                    Account Details:
                                </div>
                                <div className="flex-grow border-t border-slate-300 "></div>
                            </div>
                            <div className="border-l border-r border-b border-slate-300 rounded-b p-4 ">
                                <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                                    {/* Credit Ref: */}
                                    <div className="flex items-center gap-2 md:gap-[4.5rem]">
                                        <div className="text-xs text-slate-400 font-[600]">
                                            Credit Ref:
                                        </div>
                                        <div className="text-sm font-[500]">
                                            200
                                        </div>
                                    </div>
                                    {/* UpLine Balance: */}
                                    <div className=" flex items-center gap-1 md:gap-8">
                                        <div className="text-xs text-slate-400 font-[600] whitespace-nowrap">
                                            UpLine Balance:
                                        </div>
                                        <div className="text-sm font-[500]">
                                            100
                                        </div>
                                    </div>
                                    {/* Max Bet: */}
                                    <div className="flex items-center gap-2 md:gap-[5.2rem]">
                                        <div className="text-xs text-slate-400 font-[600] whitespace-nowrap">
                                            Max Bet:
                                        </div>
                                        <div className="text-sm font-[500]">
                                            100
                                        </div>
                                    </div>

                                    {/* */}
                                    <div className="flex items-center gap-2 md:gap-24">
                                        <div className="text-xs text-slate-400 font-[600]">
                                            Balance:
                                        </div>
                                        <div className="text-sm font-[500]">
                                            100
                                        </div>
                                    </div>
                                    {/* DownLine Balance: */}
                                    <div className="flex items-center gap-2 md:gap-5">
                                        <div className="text-xs text-slate-400 font-[600] whitespace-nowrap">
                                            DownLine Balance:
                                        </div>
                                        <div className="text-sm font-[500]">
                                            100
                                        </div>
                                    </div>
                                    {/*Bet Lock:*/}
                                    <div className="flex items-center gap-2 md:gap-[5.6rem]">
                                        <div className="text-xs text-slate-400 font-[600] whitespace-nowrap">
                                            Bet Lock:
                                        </div>
                                        <div className="text-sm font-[500]">
                                            No
                                        </div>
                                    </div>

                                    {/* Available Balance:*/}
                                    <div className="flex items-center gap-1 md:gap-4">
                                        <div className="text-xs text-slate-400 font-[600] whitespace-nowrap">
                                            Available Balance:
                                        </div>
                                        <div className="text-sm font-[500]">
                                            100
                                        </div>
                                    </div>
                                    {/* Exposure : */}
                                    <div className="flex items-center gap-2 md:gap-4">
                                        <div className="text-xs text-slate-400 font-[600]">
                                            Exposure :
                                        </div>
                                        <div className="text-sm font-[500]">
                                            100
                                        </div>
                                    </div>
                                    {/* Active : */}
                                    <div className="flex items-center gap-2 md:gap-4">
                                        <div className="text-xs text-slate-400 font-[600]">
                                            Active :
                                        </div>
                                        <div className="text-sm font-[500]">
                                            Yes
                                        </div>
                                    </div>

                                    {/* P/l */}
                                    <div className="flex items-center gap-2 md:gap-4">
                                        <div className="text-xs text-slate-400 font-[600]">
                                            P/L :
                                        </div>
                                        <div className="text-sm font-[500]">
                                            100
                                        </div>
                                    </div>
                                    {/* Max Profit: */}
                                    <div className="flex items-center gap-2 md:gap-4">
                                        <div className="text-xs text-slate-400 font-[600] whitespace-nowrap">
                                            Max Profit:
                                        </div>
                                        <div className="text-sm font-[500]">
                                            100
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Game Play: */}
                    <div className="">
                        <div className="relative p-1 md:p-4 ">
                            {/* Game Play: */}
                            <div className="flex items-center my-[-10px]">
                                <div className="border-t border-slate-300 pl-4 "></div>
                                <div className=" text-[14px] font-bold  px-2 ml-[0px] ">
                                    Game Play:
                                </div>
                                <div className="flex-grow border-t border-slate-300 "></div>
                            </div>
                            <div className="border-l border-r border-b border-slate-300 rounded-b p-1 md:p-4 ">
                                <div className="border p-1 md:p-2 rounded">
                                    <div className="border grid grid-cols-3 rounded">
                                        <div className="text-center">
                                            <div>P&L</div>
                                            <div>1</div>
                                        </div>
                                        <div className="text-center">
                                            <div>Commission</div>
                                            <div>0</div>
                                        </div>
                                        <div className="text-center">
                                            <div>Total Bet</div>
                                            <div>5</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-2 my-2">
                                    <div className=" md:p-2 rounded">
                                        <table className="w-full text-sm text-center ">
                                            <thead>
                                                <tr>
                                                    <th className="border p-1">
                                                        Sport
                                                    </th>
                                                    <th className="border p-1">
                                                        Bet
                                                    </th>
                                                    <th className="border p-1">
                                                        Bet Amount
                                                    </th>
                                                    <th className="border p-1">
                                                        P & L
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-gray-200">
                                                    <td className="border p-1">
                                                        Cricket
                                                    </td>
                                                    <td className="border p-1">
                                                        1
                                                    </td>
                                                    <td className="border p-1">
                                                        100
                                                    </td>
                                                    <td className="border p-1">
                                                        100
                                                    </td>
                                                </tr>
                                                <tr className="bg-white">
                                                    <td className="border p-1">
                                                        Totla
                                                    </td>
                                                    <td className="border p-1">
                                                        1
                                                    </td>
                                                    <td className="border p-1">
                                                        100
                                                    </td>
                                                    <td className="border p-1">
                                                        100
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className=" md:p-2 rounded">
                                        <table className="w-full text-sm text-center">
                                            <thead>
                                                <tr>
                                                    <th className="border p-1">
                                                        Casino
                                                    </th>
                                                    <th className="border p-1">
                                                        Total P & L
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-gray-200">
                                                    <td className="border p-1">
                                                        SPRIBE
                                                    </td>
                                                    <td className="border p-1">
                                                        14.00
                                                    </td>
                                                </tr>
                                                <tr className="bg-white">
                                                    <td className="border p-1">
                                                        Total
                                                    </td>
                                                    <td className="border p-1">
                                                        14.00
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className=" md:p-2 rounded">
                                        <table className="w-full text-sm text-center">
                                            <thead>
                                                <tr>
                                                    <th className="border p-1">
                                                        Sport
                                                    </th>
                                                    <th className="border p-1">
                                                        Market
                                                    </th>
                                                    <th className="border p-1">
                                                        P & L
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-gray-200">
                                                    <td className="border p-1">
                                                        Cricket
                                                    </td>
                                                    <td className="border p-1">
                                                        BOOKMAKER
                                                    </td>
                                                    <td className="border p-1">
                                                        -13.00
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewUserDetails;
