import React from "react";
// import UserDetails from "./UserDetails";

const UserDetails = () => {
    return (
        <div className="w-full">
            <div className="bg-white m-2 p-2 w-full">
                {/* user search section */}
                <div className="px-4">
                    <div className="text-[#16b0c8] text-[16px] font-bold my-2">
                        User Details
                    </div>
                    <input
                        type="text"
                        placeholder="Search by client"
                        className="border-2 p-[px] pl-2 rounded-md w-full md:w-[15rem]"
                    />
                </div>

                {/* conditional rendering section */}

                <div className="relative p-4">
                    {/* user details */}
                    <div className="flex items-center my-[-10px]">
                        <div className="border-t border-[#16b0c8] pl-4 "></div>
                        <div className="text-[#16b0c8] text-[14px] font-bold bg-white px-2 ml-[0px] ">
                            UserDetails:
                        </div>
                        <div className="flex-grow border-t border-[#16b0c8] "></div>
                    </div>
                    <div className="border-l border-r border-b border-[#16b0c8] rounded-b p-4 ">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
                            {/* first column */}
                            <div className="flex items-start flex-col gap-2">
                                {/* User Name : */}
                                <div className="flex items-center gap-8 md:gap-10">
                                    <div className="text-sm whitespace-nowrap text-[#3796a8] font-[600]">
                                        User Name :
                                    </div>
                                    <div className=" text-sm font-[500]">
                                        radheuser99
                                    </div>
                                </div>
                                {/* Client Name : */}
                                <div className="flex items-center gap-6 md:gap-8">
                                    <div className=" text-sm whitespace-nowrap text-[#3796a8] font-[600]">
                                        Client Name :
                                    </div>
                                    <div className="text-sm font-[500]">
                                        radheuser99
                                    </div>
                                </div>
                                {/* Email : */}
                                <div className="text-sm flex items-center gap-16 md:gap-20 whitespace-nowrap">
                                    <div className="text-[#3796a8] font-[600]">
                                        Email :
                                    </div>
                                    <div className="text-sm font-[500]">
                                        XYZ123@gmail.com
                                    </div>
                                </div>
                                {/* City : */}
                                <div className=" text-sm flex items-center gap-[4.5rem] md:gap-[5.6rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        City :
                                    </div>
                                    <div className="text-sm font-[500]">
                                        XYZ
                                    </div>
                                </div>
                                {/* Parents : */}
                                <div className="text-sm flex items-center gap-[3rem] md:gap-[3.8rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        Parents :
                                    </div>
                                    <div className="text-sm font-[500]">
                                        admin99radhe
                                    </div>
                                </div>
                            </div>
                            {/* second column */}
                            <div className="flex items-start flex-col gap-2">
                                {/* Role : */}
                                <div className="text-sm flex items-center gap-[4.2rem] md:gap-28">
                                    <div className="text-[#3796a8] font-[600]">
                                        Role :
                                    </div>
                                    <div className=" font-[500]">Client</div>
                                </div>
                                {/* Reference Name : */}
                                <div className=" text-sm flex items-center gap-1 md:gap-6">
                                    <div className="text-[#3796a8] font-[600]">
                                        Reference Name :
                                    </div>
                                    <div className="text-sm font-[500]">
                                        XYZ
                                    </div>
                                </div>
                                {/* Mobile : */}
                                <div className=" text-sm flex items-center gap-[3.5rem] md:gap-[5.6rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        Mobile :
                                    </div>
                                    <div className="text-sm font-[500]">
                                        0123456789
                                    </div>
                                </div>
                                {/* Remarks : */}
                                <div className="text-sm flex items-center gap-[3rem] md:gap-20">
                                    <div className="text-[#3796a8] font-[600]">
                                        Remarks :
                                    </div>
                                    <div className="text-sm font-[500]">
                                        XYZ
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Account details */}
                <div className="relative p-4">
                    {/* Account Details: */}
                    <div className="flex items-center my-[-10px]">
                        <div className="border-t border-[#16b0c8] pl-4 "></div>
                        <div className="text-[#16b0c8] text-[14px] font-bold bg-white px-2 ml-[0px] ">
                            Account Details:
                        </div>
                        <div className="flex-grow border-t border-[#16b0c8] "></div>
                    </div>
                    <div className="border-l border-r border-b border-[#16b0c8] rounded-b p-4 ">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {/* first column */}
                            <div className="flex items-start flex-col gap-2">
                                {/* Credit Ref: */}
                                <div className=" text-sm flex items-center gap-[4.5rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        Credit Ref:
                                    </div>
                                    <div className=" font-[500]">200</div>
                                </div>
                                {/*Available Balance: */}
                                <div className=" text-sm flex items-center gap-6 md:gap-4">
                                    <div className="text-[#3796a8] font-[600]">
                                        Available Balance:
                                    </div>
                                    <div className=" font-[500]">100</div>
                                </div>
                                {/* UpLine Balance: */}
                                <div className=" text-sm flex items-center gap-9 md:gap-8">
                                    <div className="text-[#3796a8] font-[600]">
                                        UpLine Balance:
                                    </div>
                                    <div className=" font-[500]">100</div>
                                </div>
                                {/* Exposure : */}
                                <div className=" text-sm flex items-center gap-[4.5rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        Exposure :
                                    </div>
                                    <div className=" font-[500]">100</div>
                                </div>
                                {/* Max Bet: */}
                                <div className="text-sm flex items-center gap-[5.2rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        Max Bet:
                                    </div>
                                    <div className=" font-[500]">100</div>
                                </div>
                                {/* Active : */}
                                <div className="text-sm flex items-center gap-[5.8rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        Active :
                                    </div>
                                    <div className="font-[500]">Yes</div>
                                </div>
                            </div>
                            {/* second column */}
                            <div className="text-sm flex items-start flex-col gap-2">
                                {/* */}
                                <div className="flex items-center gap-[5.2rem] md:gap-24">
                                    <div className="text-[#3796a8] font-[600]">
                                        Balance:
                                    </div>
                                    <div className=" font-[500]">100</div>
                                </div>
                                {/* P/l */}
                                <div className=" text-sm flex items-center gap-[6.8rem] md:gap-[7.8rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        P/L :
                                    </div>
                                    <div className="font-[500]">100</div>
                                </div>
                                {/* DownLine Balance: */}
                                <div className=" text-sm flex items-center gap-4 md:gap-5">
                                    <div className="text-[#3796a8] font-[600]">
                                        DownLine Balance:
                                    </div>
                                    <div className="font-[500]">100</div>
                                </div>
                                {/* Max Profit: */}
                                <div className=" text-sm flex items-center gap-[4.2rem] md:gap-[4.8rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        Max Profit:
                                    </div>
                                    <div className="font-[500]">100</div>
                                </div>
                                {/*Bet Lock:*/}
                                <div className="text-sm flex items-center gap-20 md:gap-[5.6rem]">
                                    <div className="text-[#3796a8] font-[600]">
                                        Bet Lock:
                                    </div>
                                    <div className="font-[500]">No</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
