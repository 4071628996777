// src/components/button/Button.jsx
import React from "react";
import PropTypes from "prop-types";

const Button = ({ type, children, onClick }) => {
    let buttonClass = "";

    switch (type) {
        case "submit":
            buttonClass =
                "bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs md:text-s text-white font-medium py-2 md:py-2 md:px-8 px-8 mx-1  mt-3 md:mt-0 rounded bg-button cursor-pointer";
            break;
        case "reset":
            buttonClass =
                "bg-[#dc3545] hover:bg-red-700 text-white text-xs md:text-s  font-medium py-2 md:py-2 md:px-8 px-8 mt-3 md:mt-0  rounded cursor-pointer";
            break;
        default:
            buttonClass =
                "bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded";
            break;
    }

    return (
        <button type={type} className={buttonClass} onClick={onClick}>
            {children}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(["button", "submit", "reset"]),
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    type: "button",
    onClick: () => {},
};

export default Button;
