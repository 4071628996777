import React, { useState } from "react";

const CurrentBets = () => {
    const [activeButton, setActiveButton] = useState("1");
    const [dateTime, setDateTime] = useState("");

    const handleChange = (event) => {
        setDateTime(event.target.value);
    };
    const buttonClasses = (button) =>
        `px-4 py-1 ${
            activeButton === button
                ? "text-white bg-[#11859c] bg-gradient-to-b from-[#11859c] to-[#181818] border-[#72bbef] text-xs md:text-sm"
                : "bg-[#88d6e3] border-[#72bbef] hover:bg-buttonHover text-xs ms:text-sm"
        }`;

    return (
        <div>
            <div className="m-2 bg-white p-4">
                {/* searching fields */}
                <div className="">
                    <div>Account Statement Report</div>
                    <div className="flex items-center gap-4 flex-wrap">
                        <input
                            type="datetime-local"
                            id="datetime"
                            value={dateTime}
                            onChange={handleChange}
                            className="w-[15rem] p-1 border rounded"
                        />
                        <input
                            type="datetime-local"
                            id="datetime"
                            value={dateTime}
                            onChange={handleChange}
                            className="w-[15rem] p-1 border rounded"
                        />
                        <select className="w-[15rem] p-1 border px-1 rounded">
                            <option>Select Sport</option>
                            <option>Cricket</option>
                            <option>Soccer</option>
                            <option>Tennis</option>
                            <option>kabaddi</option>
                            <option>Election</option>
                            <option>kabaddi</option>
                            <option>Horse Racing</option>
                            <option>Greyhound Racing</option>
                            <option>Binary</option>
                            <option>SportBook</option>
                            <option>Indian Pokar/Live Casino</option>
                            <option>Indian Pokar ||</option>
                            <option>Evolution</option>
                            <option>Vivo</option>
                            <option>WAC</option>
                            <option>Betgames</option>
                            <option>Casino |||</option>
                            <option>Spribe</option>
                        </select>
                        <select className="w-[15rem] p-1 border px-1 rounded">
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                        </select>
                        <select className="w-[15rem] p-1 border px-1 rounded">
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                        </select>

                        <select className="w-[12rem] p-1 border px-1 rounded">
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                        </select>
                        <input
                            type="datetime-local"
                            id="datetime"
                            value={dateTime}
                            onChange={handleChange}
                            className="w-[15rem] p-1 border rounded"
                        />
                        <div className="flex">
                            <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1  mt-3 md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                Go
                            </button>
                            <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1  mt-3 md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>

                <div className="my-4 flex items-end justify-between">
                    {/* search */}
                    <div className="flex items-center gap-2">
                        <input
                            type="text"
                            placeholder="Search"
                            className="p-1 border rounded"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/csv-icon.svg"
                            alt="csv icon"
                            title="Export Data"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/pdf-icon.svg"
                            alt="pdf icon"
                            title="Export Data"
                        />
                    </div>

                    {/* entries  */}
                    <div className="flex items-center gap-2">
                        <div>Show</div>
                        <select className="border rounded p-1">
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                        </select>
                        <div>entries</div>
                    </div>
                </div>

                {/* table */}
                <div className="border p-2 rounded">
                    <table className="w-full border-collapse border">
                        <thead>
                            <tr className="bg-[#007082] text-white text-sm text-left">
                                <th className="border p-2">Date & Time</th>
                                <th className="border p-2">User</th>
                                <th className="border p-2">Competition</th>
                                <th className="border p-2">Event</th>
                                <th className="border p-2">Market</th>
                                <th className="border p-2">Runner</th>
                                <th className="border p-2">Side</th>
                                <th className="border p-2">Line</th>
                                <th className="border p-2">Rate</th>
                                <th className="border p-2">Amount</th>
                                <th className="border p-2">PP</th>
                                <th className="border p-2">PL</th>
                                <th className="border p-2">Client IP</th>
                            </tr>
                        </thead>
                        <tbody className="text-xs font-medium">
                            <tr className="bg-gray-200">
                                <td className="border p-2">05/07/2024 00:00</td>
                                <td className="border p-2">User1</td>
                                <td className="border p-2">Competition1</td>
                                <td className="border p-2">Event1</td>
                                <td className="border p-2">Market1</td>
                                <td className="border p-2">Runner1</td>
                                <td className="border p-2">Side1</td>
                                <td className="border p-2">Line1</td>
                                <td className="border p-2">Rate1</td>
                                <td className="border p-2">100</td>
                                <td className="border p-2">PP1</td>
                                <td className="border p-2">PL1</td>
                                <td className="border p-2">192.168.1.1</td>
                            </tr>
                            <tr className="bg-white">
                                <td className="border p-2">05/07/2024 01:00</td>
                                <td className="border p-2">User2</td>
                                <td className="border p-2">Competition2</td>
                                <td className="border p-2">Event2</td>
                                <td className="border p-2">Market2</td>
                                <td className="border p-2">Runner2</td>
                                <td className="border p-2">Side2</td>
                                <td className="border p-2">Line2</td>
                                <td className="border p-2">Rate2</td>
                                <td className="border p-2">200</td>
                                <td className="border p-2">PP2</td>
                                <td className="border p-2">PL2</td>
                                <td className="border p-2">192.168.1.2</td>
                            </tr>
                            <tr className="bg-gray-200">
                                <td className="border p-2">05/07/2024 02:00</td>
                                <td className="border p-2">User3</td>
                                <td className="border p-2">Competition3</td>
                                <td className="border p-2">Event3</td>
                                <td className="border p-2">Market3</td>
                                <td className="border p-2">Runner3</td>
                                <td className="border p-2">Side3</td>
                                <td className="border p-2">Line3</td>
                                <td className="border p-2">Rate3</td>
                                <td className="border p-2">300</td>
                                <td className="border p-2">PP3</td>
                                <td className="border p-2">PL3</td>
                                <td className="border p-2">192.168.1.3</td>
                            </tr>
                            <tr className="bg-white">
                                <td className="border p-2">05/07/2024 03:00</td>
                                <td className="border p-2">User4</td>
                                <td className="border p-2">Competition4</td>
                                <td className="border p-2">Event4</td>
                                <td className="border p-2">Market4</td>
                                <td className="border p-2">Runner4</td>
                                <td className="border p-2">Side4</td>
                                <td className="border p-2">Line4</td>
                                <td className="border p-2">Rate4</td>
                                <td className="border p-2">400</td>
                                <td className="border p-2">PP4</td>
                                <td className="border p-2">PL4</td>
                                <td className="border p-2">192.168.1.4</td>
                            </tr>
                        </tbody>
                    </table>

                    {/* Footer */}
                    <div className="flex flex-col  md:flex-row justify-between items-center mt-4 w-full md:px-4">
                        <div className="text-sm mb-2 md:mb-0">
                            Showing 1 to 4 of 5 entries
                        </div>
                        <div className="flex">
                            {["First", "Prev", "1", "Next", "Last"].map(
                                (button, index) => (
                                    <button
                                        key={index}
                                        className={buttonClasses(button)}
                                        onClick={() => setActiveButton(button)}
                                    >
                                        {button}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CurrentBets;
