import React from "react";
import { Outlet } from "react-router";

const Reports = () => {
    return (
        <div>
            <Outlet />
        </div>
    );
};

export default Reports;
