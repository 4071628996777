import React, { useState } from "react";
import { PiArrowsDownUpLight } from "react-icons/pi";
import { useLocation } from "react-router-dom";

const Table = ({ columns, data }) => {
    const location = useLocation();
    const [activeButton, setActiveButton] = useState("1");

    const buttonClasses = (button) =>
        `px-4 py-1 ${
            activeButton === button
                ? "text-white bg-[#11859c] bg-gradient-to-b from-[#11859c] to-[#181818] border-[#72bbef] text-xs md:text-sm"
                : "bg-[#88d6e3] border-[#72bbef] hover:bg-buttonHover text-xs ms:text-sm"
        }`;

    const getActionBackgroundColor = (actionValue) => {
        switch (actionValue) {
            case "U":
                return "bg-[#ff7f50]";
            case "D|C":
                return "bg-[#008000]";
            case "W":
                return "bg-[#42c2e2]";
            case "P":
                return "bg-[#ff0] text-black";
            case "GC":
                return "bg-[#eb99e0] text-black";
            case "CC":
                return "bg-[#47ee31] text-black";
            default:
                return "";
        }
    };

    return (
        <div className=" my-2 px-1 md:px-2 ">
            <div className=" overflow-hidden w-full">
                <div className="border rounded-lg overflow-x-auto   ">
                    <table className="text-xs md:text-sm ">
                        <thead className="bg-[#016a82] text-white">
                            <tr>
                                {columns.map((column, index) => (
                                    <th
                                        key={index}
                                        className="px-4 py-2 border text-[14px] font-semibold whitespace-nowrap"
                                    >
                                        <div className="flex items-center gap-1">
                                            {column}
                                            {location.pathname ===
                                                "/clients" && (
                                                <PiArrowsDownUpLight />
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="">
                            {data.map((row, rowIndex) => (
                                <tr
                                    key={rowIndex}
                                    className={
                                        rowIndex % 2 === 0 ? "bg-gray-200" : ""
                                    }
                                >
                                    {columns.map((column, colIndex) => (
                                        <td
                                            key={colIndex}
                                            className={`px-4 py-2 border ${
                                                column === "Actions"
                                                    ? "flex justify-center gap-2 items-center"
                                                    : ""
                                            }`}
                                        >
                                            {column === "Actions" ? (
                                                row[column]
                                                    .split(",")
                                                    .map((action, index) => (
                                                        <div
                                                            key={index}
                                                            className={`px-1 text-white text-[12px] font-bold rounded ${getActionBackgroundColor(
                                                                action.trim()
                                                            )}`}
                                                        >
                                                            {action.trim()}
                                                        </div>
                                                    ))
                                            ) : column === "User Name" &&
                                              row[column] ? (
                                                <div className="flex items-center gap-2">
                                                    <img
                                                        className="w-[14px] h-[14px] rounded-full"
                                                        src={row.icon}
                                                        alt="user"
                                                    />
                                                    <a
                                                        href={row[column]}
                                                        className="text-blue-500 underline text-sm font-medium"
                                                    >
                                                        {row[column]}
                                                    </a>
                                                </div>
                                            ) : typeof row[column] ===
                                              "boolean" ? (
                                                <input
                                                    type="checkbox"
                                                    checked={row[column]}
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        transform: "scale(1.5)",
                                                    }}
                                                />
                                            ) : typeof row[column] ===
                                                  "string" &&
                                              row[column].includes("http") ? (
                                                <a
                                                    href={row[column]}
                                                    className="text-blue-500"
                                                >
                                                    {row[column]}
                                                </a>
                                            ) : (
                                                row[column]
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Footer */}
            <div className="flex flex-col  md:flex-row justify-between items-center mt-4 w-full md:px-4">
                <div className="text-sm mb-2 md:mb-0">
                    Showing 1 to {data.length} of {data.length} entries
                </div>
                <div className="flex">
                    {["First", "Prev", "1", "Next", "Last"].map(
                        (button, index) => (
                            <button
                                key={index}
                                className={buttonClasses(button)}
                                onClick={() => setActiveButton(button)}
                            >
                                {button}
                            </button>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default Table;
