import React from "react";
import Button from "./../../button/Button";
import Table from "../../table/Table";

const clientColumns = [
    "User Name",
    "Credit Reference",
    "Balance",
    "Pending Bal.",
    "Available Bal.",
    "Current P&L",
    "Exposure",
    "U Lock",
    "B Lock",
    "My %",
    "Type",
    "Actions",
];

const clientData = [
    {
        "User Name": "",
        "Credit Reference": "300.00",
        Balance: "800.00",
        "Pending Bal.": "-500.00",
        "Available Bal.": "500.00",
        "Current P&L": "300.00",
        Exposure: "0.00",
        Type: "",
        Actions: "",
    },
    {
        "User Name": "Chintu41",
        icon: "https://images.staticcontent.io/rde/aimg/Master-icon.svg",
        "Credit Reference": "0.00",
        Balance: "0.00",
        "Pending Bal.": "0.00",
        "Available Bal.": "0.00",
        "Current P&L": "0.00",
        Exposure: "0.00",
        "U Lock": false,
        "B Lock": false,
        "My %": "0%",
        Type: "Master",
        Actions: "U, D|C, W, P, GC, CC",
    },
    {
        "User Name": "Rohit123",
        icon: "https://images.staticcontent.io/rde/aimg/SubSuperMaster-icon.svg",
        "Credit Reference": "0.00",
        Balance: "0.00",
        "Pending Bal.": "0.00",
        "Available Bal.": "0.00",
        "Current P&L": "0.00",
        Exposure: "0.00",
        "U Lock": false,
        "B Lock": false,
        "My %": "0%",
        Type: "Master",
        Actions: "U, D|C, W, P, GC, CC",
    },
    {
        "User Name": "Sunil",
        icon: "https://images.staticcontent.io/rde/aimg/Client-icon.svg",
        "Credit Reference": "0.00",
        Balance: "0.00",
        "Pending Bal.": "0.00",
        "Available Bal.": "0.00",
        "Current P&L": "0.00",
        Exposure: "0.00",
        "U Lock": false,
        "B Lock": false,
        "My %": "0%",
        Type: "Master",
        Actions: "U, D|C, W, P, GC, CC",
    },
];

const Clients = () => {
    return (
        <div className="bg-white h-full overflow-x-hidden ">
            <div
                className="text-base font-bold px-1 md:px-2 "
                style={{ color: "rgb(22, 176, 200)" }}
            >
                Client List
            </div>
            <div className="p- flex flex-col md:flex-row md:items-center md:justify-between px-1 md:px-2 ">
                <div className="flex items-center justify-start">
                    <div className="flex items-center gap-1 lg:gap-4">
                        <input
                            type="text"
                            placeholder="Search"
                            className="border text-xs rounded p-[5px] w-[42%]"
                        />
                        <input
                            type="text"
                            placeholder="Search by client"
                            className="border text-xs rounded p-[5px] w-[42%]"
                        />
                    </div>
                    <div className="flex justify-end items-start md:gap-2">
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/csv-icon.svg"
                            alt="csv icon"
                            title="Export Data"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/pdf-icon.svg"
                            alt="pdf icon"
                            title="Export Data"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/csv-icon.svg"
                            alt="csv icon"
                            title="Export All Data"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/pdf-icon.svg"
                            alt="pdf icon"
                            title="Export All Data"
                        />
                    </div>
                </div>
                <div className="flex justify-start my-1">
                    <Button type="submit" children="Add Client Account" />
                    <Button type="submit" children="Inactive List" />
                </div>
            </div>
            <div className="overflow-hidden w-screen">
                <Table columns={clientColumns} data={clientData} />
            </div>
        </div>
    );
};

export default Clients;
