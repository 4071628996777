// src/routes.js
import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Root from "../components/layout/root/Root";
import Dashboard from "./../components/pages/dashboard/Dashboard";
import Clients from "./../components/pages/clients/Clients";
import SportAnalysis from "./../components/pages/sportAnalysis/SportAnalysis";
import CasinoAnalysis from "./../components/pages/casinoAnalysis/CasinoAnalysis";
import Settlement from "./../components/pages/settlement/Settlement";
import Reports from "./../components/pages/reports/Reports";
import Cutting from "./../components/pages/cutting/Cutting";
import Cricket from "./../components/pages/cricket/Cricket";
import Soccer from "./../components/pages/soccer/Soccer";
import Tennis from "./../components/pages/tennis/Tennis";
import Horse from "./../components/pages/horse/Horse";
import Greyhound from "./../components/pages/greyhound/Greyhound";
import User from "../components/settlementNTTS/user/User";
import Master from "../components/settlementNTTS/master/Master";
import Login from "../auth/login/Login";
import UserDetails from "../components/reportsntts/user-detail/UserDetails";
import AccountStatement from "./../components/reportsntts/account-statement/AccountStatement";
import BalanceReport from "./../components/reportsntts/settlement-balance-report/BalanceReport";
import CurrentBets from "./../components/reportsntts/current-bets/CurrentBets";
import ProfitLoss from "./../components/reportsntts/profit-loss-report/ProfitLoss";
import EvenProftLoss from "./../components/reportsntts/event-profit-loss-report/EvenProftLoss";
import BetHistory from "./../components/reportsntts/bet-history/BetHistory";
import LiveBets from "./../components/reportsntts/live-bets/LiveBets";
import SportsRevenue from "./../components/reportsntts/sports-revenue/SportsRevenue";
import TransactionReport from "./../components/reportsntts/transaction-report/TransactionReport";
import NewUserDetails from "./../components/reportsntts/user-detail-new/NewUserDetails";
import ChangePassword from "../components/pages/changePassword/ChangePassword";
import AgentMaster from "./../cuttingNTTS/agentMaster/AgentMaster";
import CuttingHistory from "./../cuttingNTTS/cutinghistory/CuttingHistory";
import Control from "../components/pages/control/Control";
import CasinoLock from "../controlNTTS/CasinoLock/CasinoLock";
import GameLock from "../controlNTTS/GameLock/GameLock";

const ProtectedRoute = ({ element }) => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    return isAuthenticated ? element : <Navigate to="/auth" />;
};

const routes = createBrowserRouter([
    { path: "/", element: <Login /> },
    {
        path: "/",
        element: <ProtectedRoute element={<Root />} />,
        children: [
            { path: "/", element: <Navigate to="/dashboard" replace /> },
            {
                path: "dashboard",
                element: <ProtectedRoute element={<Dashboard />} />,
            },
            {
                path: "clients",
                element: <ProtectedRoute element={<Clients />} />,
            },
            {
                path: "sport-analysis",
                element: <ProtectedRoute element={<SportAnalysis />} />,
            },
            {
                path: "casino-analysis",
                element: <ProtectedRoute element={<CasinoAnalysis />} />,
            },
            {
                path: "settlement",
                element: <ProtectedRoute element={<Settlement />} />,
                children: [
                    {
                        path: "user",
                        element: <ProtectedRoute element={<User />} />,
                    },
                    {
                        path: "master",
                        element: <ProtectedRoute element={<Master />} />,
                    },
                ],
            },
            {
                path: "reports",
                element: <ProtectedRoute element={<Reports />} />,
                children: [
                    {
                        path: "userdetail",
                        element: <ProtectedRoute element={<UserDetails />} />,
                    },
                    {
                        path: "userdetailnew",
                        element: (
                            <ProtectedRoute element={<NewUserDetails />} />
                        ),
                    },
                    {
                        path: "accountstatement",
                        element: (
                            <ProtectedRoute element={<AccountStatement />} />
                        ),
                    },
                    {
                        path: "transaction",
                        element: (
                            <ProtectedRoute element={<TransactionReport />} />
                        ),
                    },
                    {
                        path: "settlementreport",
                        element: <ProtectedRoute element={<BalanceReport />} />,
                    },
                    {
                        path: "currentbets",
                        element: <ProtectedRoute element={<CurrentBets />} />,
                    },
                    {
                        path: "profitloss",
                        element: <ProtectedRoute element={<ProfitLoss />} />,
                    },
                    {
                        path: "eventPnLReport",
                        element: <ProtectedRoute element={<EvenProftLoss />} />,
                    },
                    {
                        path: "bethistory",
                        element: <ProtectedRoute element={<BetHistory />} />,
                    },
                    {
                        path: "livebets",
                        element: <ProtectedRoute element={<LiveBets />} />,
                    },
                    {
                        path: "sportsrevenue",
                        element: <ProtectedRoute element={<SportsRevenue />} />,
                    },
                ],
            },
            {
                path: "cutting",
                element: <ProtectedRoute element={<Cutting />} />,
                children: [
                    {
                        path: "agentmaster",
                        element: <ProtectedRoute element={<AgentMaster />} />,
                    },
                    {
                        path: "cuttinghistory",
                        element: (
                            <ProtectedRoute element={<CuttingHistory />} />
                        ),
                    },
                ],
            },
            {
                path: "control",
                element: <ProtectedRoute element={<Control />} />,
                children: [
                    {
                        path: "gamebetlock/admin99radhe",
                        element: <ProtectedRoute element={<GameLock />} />,
                    },
                    {
                        path: "casinocontrol/admin99radhe",
                        element: <ProtectedRoute element={<CasinoLock />} />,
                    },
                ],
            },
            // {
            //     path: "casino-control",
            //     element: <ProtectedRoute element={<CasinoControl />} />,
            // },
            {
                path: "/cricket",
                element: <ProtectedRoute element={<Cricket />} />,
            },
            {
                path: "/soccer",
                element: <ProtectedRoute element={<Soccer />} />,
            },
            {
                path: "/tennis",
                element: <ProtectedRoute element={<Tennis />} />,
            },
            { path: "/horse", element: <ProtectedRoute element={<Horse />} /> },
            {
                path: "/greyhound",
                element: <ProtectedRoute element={<Greyhound />} />,
            },
            {
                path: "/changepassword",
                element: <ProtectedRoute element={<ChangePassword />} />,
            },
        ],
    },
]);

export default routes;
