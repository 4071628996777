import Sidebar from "./../sidebar/Sidebar";
import { useState } from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./../header/Header";
const Root = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1024);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [isSmallScreen]);

    const sidebarTabs = [
        {
            path: "/cricket",
            label: "Cricket",
            icon: "https://images.staticcontent.io/casino/banner/images/Cricket.svg",
            hasDropdown: true,
        },
        {
            path: "/soccer",
            label: "Soccer",
            icon: "https://images.staticcontent.io/casino/banner/images/Soccer.svg",
            hasDropdown: true,
        },
        {
            path: "/tennis",
            label: "Tennis",
            icon: "https://images.staticcontent.io/casino/banner/images/Tennis.svg",
            hasDropdown: true,
        },
        {
            path: "/horse",
            label: "Horse",
            icon: "https://images.staticcontent.io/casino/banner/images/horse-icon.png",
            hasDropdown: true,
        },
        {
            path: "/greyhound",
            label: "Greyhound",
            icon: "https://images.staticcontent.io/casino/banner/images/grayhound-icon.png",
            hasDropdown: true,
        },
    ];

    const allTabs = [
        {
            path: "dashboard",
            label: "Dashboard",
            icon: "https://images.staticcontent.io/rde/aimg/Clients.svg",
        },
        {
            path: "clients",
            label: "Clients",
            icon: "https://images.staticcontent.io/rde/aimg/Clients.svg",
        },
        {
            path: "sport-analysis",
            label: "Sport Analysis",
            icon: "https://images.staticcontent.io/rde/aimg/MarketAnalysis.svg",
        },
        {
            path: "/casino-analysis",
            label: "Casino Analysis",
            icon: "https://images.staticcontent.io/rde/aimg/CasinoAnalysis.svg",
        },
        {
            path: "/settlement",
            label: "Settlement",
            icon: "https://images.staticcontent.io/rde/aimg/Settlement.svg",
            hasDropdown: true,
            subTabs: [
                { path: "/settlement/user", label: "User" },
                { path: "/settlement/master", label: "Master" },
            ],
        },
        {
            path: "/reports",
            label: "Reports",
            icon: "https://images.staticcontent.io/rde/aimg/Reports.svg",
            hasDropdown: true,
            subTabs: [
                { path: "/reports/userdetail", label: "User Detail" },
                { path: "/reports/userdetailnew", label: "User Detail New" },
                {
                    path: "/reports/accountstatement",
                    label: "Account Statement",
                },
                {
                    path: "/reports/transaction",
                    label: "Transaction Report",
                },
                // {
                //     path: "/reports/settlement-balance-report",
                //     label: "Settlement/Balance Report",
                // },
                { path: "/reports/currentbets", label: "Current Bets" },
                {
                    path: "/reports/profitloss",
                    label: "Profit & Loss Report",
                },
                {
                    path: "/reports/eventPnLReport",
                    label: "Event Profit & Loss Report",
                },
                { path: "/reports/bethistory", label: "Bet History" },
                { path: "/reports/livebets", label: "Live Bets" },
                { path: "/reports/sportsrevenue", label: "Sports Revenue" },
            ],
        },
        {
            path: "/cutting",
            label: "Cutting",
            icon: "https://images.staticcontent.io/rde/aimg/Reports.svg",
            hasDropdown: true,
            subTabs: [
                { path: "/cutting/agentmaster", label: "Agent Master" },
                { path: "/cutting/cuttinghistory", label: "Cutting History" },
            ],
        },
        {
            path: "/control",
            label: "Control",
            icon: "https://images.staticcontent.io/rde/aimg/GameControl.svg",
            hasDropdown: true,
            subTabs: [
                { path: "/control/gamebetlock/admin99radhe", label: "Game" },
                {
                    path: "/control/casinocontrol/admin99radhe",
                    label: "Casino",
                },
            ],
        },
    ];

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="flex w-full">
            <Sidebar
                isSmallScreen={isSmallScreen}
                allTabs={allTabs}
                sidebarTabs={sidebarTabs}
                isSidebarOpen={isSidebarOpen}
            />
            <div className="flex-1 ">
                <Header
                    isSmallScreen={isSmallScreen}
                    allTabs={allTabs}
                    toggleSidebar={toggleSidebar}
                />
                <div className="">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Root;
