// src/App.js
import React from "react";
import { RouterProvider } from "react-router-dom";
import Routes from "./routes/Routes"; // Import routes from routes.js

const App = () => {
    return <RouterProvider router={Routes} />;
};

export default App;
