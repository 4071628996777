import React, { useEffect, useState } from "react";
import Button from "../../button/Button";
// import WelcomePage from "../../welcomePage/WelcomePage";
import { IoClose } from "react-icons/io5";
const Dashboard = () => {
    const [welcomePage, setWelcomePage] = useState(true);

    useEffect(() => {
        const storedWelcomePage = localStorage.getItem("welcomePage");
        if (storedWelcomePage !== null) {
            setWelcomePage(JSON.parse(storedWelcomePage));
        }
    }, []);

    const onClose = () => {
        setWelcomePage(false);
        localStorage.setItem("welcomePage", JSON.stringify(false));
    };
    return (
        <>
            {welcomePage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
                    <div className="relative w-[80%] h-[70%]">
                        <img
                            className="w-full h-full object-cover"
                            src="https://images.staticcontent.io/splashpopup/ADMINDESKTOP_splashpopup.jpg"
                            alt="welcome page"
                        />
                        <button
                            onClick={onClose}
                            className="absolute top-0 right-0 m-4 p-1 bg-white rounded shadow-md"
                        >
                            <IoClose size={24} color="#000" />
                        </button>
                    </div>
                </div>
            )}
            <div className="px-2 md:p-2">
                {/* form */}
                <div className="bg-white flex flex-col md:flex-row items-start gap-1 md:gap-4 p-1 md:p-2 shadow-lg">
                    <div className="flex items-center justify-between md:justify-normal md:gap-5 w-full px-1 ">
                        <div className="flex flex-col items-start">
                            <label>Form Date:123</label>
                            <input
                                type="text"
                                value="24/06/2024 00:00"
                                className=" border-2 border-[#ced4da] pl-2 text-[#495057] rounded my-1 w-[8.5rem] md:w-auto"
                            />
                        </div>
                        <div className="flex flex-col items-start">
                            <label>To Date:</label>
                            <input
                                type="text"
                                value="01/07/2024 00:00"
                                className="border-2 border-[#ced4da] pl-2 text-[#495057] rounded my-1 w-[8.5rem] md:w-auto"
                            />
                        </div>
                    </div>
                    <div className="flex items-center ">
                        <Button type="submit">Submit</Button>
                        <Button type="reset">Reset</Button>
                    </div>
                </div>

                <div className="w-full grid grid-cols-2 md:grid-cols-3 beyond-1024:grid-cols-6 gap-3 mt-4">
                    {/* P&L */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-sm p-1">
                            P&L
                        </div>
                        <div className="py-3 text-xl font-semibold px-2">0</div>
                    </div>
                    {/* COMMISSION */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-sm p-1">
                            COMMISSION
                        </div>
                        <div className="py-3 text-xl font-semibold px-2">0</div>
                    </div>
                    {/* Deposit */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-base p-1">
                            DEPOSIT
                        </div>
                        <div className="py-3 text-xl font-semibold px-2">0</div>
                    </div>
                    {/* WITDRAWAL*/}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-base p-1">
                            WITDRAWAL
                        </div>
                        <div className="py-3 text-xl font-semibold px-2">0</div>
                    </div>
                    {/* TOTAL BETS */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-base p-1">
                            TOTAL BETS
                        </div>
                        <div className="py-3 text-xl font-semibold px-2">0</div>
                    </div>
                    {/* SPORTBOOK  */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-base p-1">
                            {" "}
                            SPORTBOOK P&L{" "}
                        </div>
                        <div className="py-3 text-xl font-semibold px-2">0</div>
                    </div>
                </div>

                {/* winner and loseing palyer */}
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-4">
                    {/* TOP 5 WINNING PLAYER */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-sm p-1">
                            TOP 5 WINNING PLAYER
                        </div>
                        <div className="py-1 font-medium text-[12px]  px-2 flex items-center justify-between">
                            <div>Player</div>
                            <div>Amount</div>
                        </div>
                    </div>

                    {/* TOP 5 LOSING PLAYER
                     */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-sm p-1">
                            TOP 5 LOSING PLAYER
                        </div>
                        <div className="py-1 font-medium text-[12px]  px-2 flex items-center justify-between">
                            <div>Player</div>
                            <div>Amount</div>
                        </div>
                    </div>

                    {/* TOP 5 WINNING MARKETS */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-sm p-1">
                            TOP 5 WINNING PLAYER
                        </div>
                        <div className="py-1 font-medium text-[12px]  px-2 flex items-center justify-between">
                            <div>Player</div>
                            <div>Amount</div>
                        </div>
                    </div>

                    {/*   TOP 5 WINNING PLAYER*/}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-sm p-1">
                            TOP 5 WINNING PLAYER
                        </div>
                        <div className="py-1 font-medium text-[12px]  px-2 flex items-center justify-between">
                            <div>Sport</div>
                            <div>Market</div>
                            <div>Amount</div>
                        </div>
                    </div>

                    {/* USER COUNT */}
                    <div
                        className=" rounded shadow-lg bg-white "
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-sm p-1">
                            User
                        </div>
                        <div className="py-1 font-medium text-[12px]  px-2 flex items-center justify-between">
                            <div>Role</div>
                            <div>Count</div>
                        </div>
                        <div className="flex justify-between items-center px-2">
                            <div>client</div>
                            <div>1</div>
                        </div>
                    </div>

                    {/*SPORTS GAMEPLAY DETAILS  */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-base p-1">
                            SPORTS GAMEPLAY DETAILS
                        </div>
                        <div className="py-1 px-2 ">
                            <select className="border-2 py-1 hover:ring-2 rounded text-[12px]">
                                <option>Select Sports</option>
                                <option>Criclet</option>
                                <option>Soccer</option>
                                <option>Tennis</option>
                                <option>Horse</option>
                                <option>Greyhound</option>
                                <option>kabaddi</option>
                            </select>
                            <div className="py-1">
                                <div className=" text-[12px]  flex items-center justify-between bg-black bg-opacity-5">
                                    <div>Indian Poker/ Live Casino P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className=" text-[12px]  flex items-center justify-between ">
                                    <div>Indian Poker/ Live Casino P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className=" text-[12px]  flex items-center justify-between bg-black bg-opacity-5">
                                    <div>Indian Poker/ Live Casino P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                        </div>
                    </div>

                    {/*OTHERS GAMEPLAY DETAILS  */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-base p-1">
                            OTHERS GAMEPLAY DETAILS
                        </div>
                        <div className="py-1 px-2 ">
                            <select className="border-2 hover:ring-2 rounded text-[12px] py-1">
                                <option>Select Other</option>
                                <option>Election</option>
                                <option>Binary</option>
                            </select>
                            <div className="py-1">
                                <div className=" text-[12px]  flex items-center justify-between bg-black bg-opacity-5">
                                    <div>Indian Poker/ Live Casino P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className=" text-[12px]  flex items-center justify-between ">
                                    <div>Indian Poker/ Live Casino P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className=" text-[12px]  flex items-center justify-between bg-black bg-opacity-5">
                                    <div>Indian Poker/ Live Casino P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className=" text-[12px]  flex items-center justify-between ">
                                    <div>Indian Poker/ Live Casino P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                        </div>
                    </div>

                    {/* CASINO GAMEPLAY DETAILS */}
                    <div
                        className=" rounded shadow-lg bg-white"
                        style={{
                            boxShadow: "0 2px 7px 0 rgb(0 112 130 / 52%)",
                        }}
                    >
                        <div className="bg-[#16a3bb] font-medium text-base p-1">
                            CASINO GAMEPLAY DETAILS
                        </div>
                        <div className="py-1  px-2 ">
                            <div className="">
                                <div className="py-1  text-[12px] px-2 flex items-center justify-between bg-black bg-opacity-5">
                                    <div>Indian Poker/ Live Casino P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className="  text-[12px]  px-2 flex items-center justify-between">
                                    <div>Indian Poker II P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className="  text-[12px]  px-2 flex items-center justify-between bg-black bg-opacity-5">
                                    <div>Indian Poker II P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className="  text-[12px]  px-2 flex items-center justify-between">
                                    <div>Indian Poker II P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className=" text-[12px]  px-2 flex items-center justify-between bg-black bg-opacity-5">
                                    <div>Indian Poker II P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className="  text-[12px]  px-2 flex items-center justify-between">
                                    <div>Indian Poker II P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className="py-1">
                                <div className="  text-[12px]  px-2 flex items-center justify-between bg-black bg-opacity-5">
                                    <div>Indian Poker II P&L</div>
                                    <div>0</div>
                                </div>
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
