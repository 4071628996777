import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
const UserInformation = () => {
    const [toggleDetails, setToggleDetails] = useState(false);
    const toggleSection = () => setToggleDetails(!toggleDetails);
    return (
        <div className="bg-[#007082] w-full px-4 lg:py-1 pb-4 relative mt-[3rem] lg:mt-16">
            {toggleDetails ? (
                <div className="flex items-center justify-center py-2">
                    <div
                        className={`bg-white rounded-full p-1 
                        }`}
                        onClick={toggleSection}
                    >
                        <FaChevronDown className=" text-[#007082] cursor-pointer" />
                    </div>
                </div>
            ) : (
                <div className="flex items-center justify-center py-1 lg:py-2">
                    <div
                        className={`bg-white rounded-full p-1 
                        }`}
                        onClick={toggleSection}
                    >
                        <FaChevronDown className=" text-[#007082] rotate-180 cursor-pointer" />
                    </div>
                </div>
            )}

            {toggleDetails && (
                <div className="  grid grid-cols-1 gap-1 md:grid-cols-4 lg:grid-cols-5 ">
                    <div className="flex  items-center gap-20 md:gap-11  flex-nowrap    lg:gap-6">
                        <div className="text-white text-xs lg:text-sm font-semibold whitespace-nowrap">
                            User ID
                        </div>
                        <div className="text-white text-xs lg:text-sm font-medium">
                            : admin99radhe
                        </div>
                    </div>
                    <div className="  flex items-center  gap-[4.2rem] md:gap-14 lg:gap-16">
                        <div className="text-white text-xs lg:text-sm font-semibold">
                            Given Bal
                        </div>
                        <div className="text-[#33f95f]">: 1000.00</div>
                    </div>
                    <div className="  flex items-center gap-10 md:gap-1 lg:gap-[4.5rem]">
                        <div className="text-white text-xs lg:text-sm font-semibold">
                            Up Line (dena)
                        </div>
                        <div className="text-[#ff8691] "> :&nbsp;-300.00</div>
                    </div>
                    <div className="  flex items-center gap-[3.3rem] md:gap-10 lg:gap-24">
                        <div className="text-white text-xs lg:text-sm font-semibold">
                            Current P&L
                        </div>
                        <div className="text-white text-xs lg:text-sm">
                            : &nbsp; 0.00
                        </div>
                    </div>
                    <div className="  flex items-center gap-[4.8rem] md:gap-10 lg:gap-40">
                        <div className="text-white text-xs lg:text-sm font-semibold">
                            My P&L
                        </div>
                        <div className="text-white text-xs lg:text-sm">: 0</div>
                    </div>
                    <div className="  flex items-center gap-16 md:gap-[3.3rem] lg:gap-2 font-semibold">
                        <div className="text-white text-xs lg:text-sm ">
                            User Type
                        </div>
                        <div className="text-white text-xs lg:text-sm font-medium">
                            {" "}
                            : &nbsp; Admin
                        </div>
                    </div>
                    <div className="  flex items-center gap-[4.2rem] md:gap-8 lg:gap-[4.2rem]">
                        <div className="text-white text-xs lg:text-sm font-semibold">
                            Available
                        </div>
                        <div className="text-[#33f95f]">: 200.00</div>
                    </div>
                    <div className=" itemsCenter flex items-center gap-[1rem] lg:gap-16">
                        <div className="text-white text-xs lg:text-sm font-semibold">
                            Down Line (lena)
                        </div>
                        <div className="text-[#33f95f]"> : 300.00</div>
                    </div>
                    <div className="  flex items-center gap-16 md:gap-8 lg:gap-32">
                        <div className="text-white text-xs lg:text-sm font-semibold">
                            Exposure
                        </div>
                        <div className="text-white text-xs lg:text-sm">: 0</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserInformation;
