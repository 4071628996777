import React from "react";

const User = () => {
    return (
        <div className=" bg-gray-100  w-full ">
            <div className=" bg-white  shadow-md rounded-lg overflow-hidden ">
                <div className="px-2">User Settlement</div>
                <div>
                    <div className="flex flex-col gap-2 items-center lg:flex-row  lg::p-4">
                        {/* table */}
                        <div className="w-screen p-1 rounded overflow-x-auto md:w-full">
                            <table className=" border-collapse border text-xs">
                                <thead className="bg-[#007082] text-white font-bold">
                                    <tr>
                                        <th className="border p-2">Account</th>
                                        <th className="border p-2">
                                            Client(P/L)
                                        </th>
                                        <th className="border p-2 " colSpan="2">
                                            Settle Amount
                                        </th>
                                        <th className="border p-2 text-center">
                                            Remarks
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white">
                                        <td className="border p-2">
                                            radheuser99
                                        </td>
                                        <td className="border p-2">User2</td>
                                        <td className="px-2">
                                            <input
                                                placeholder="0"
                                                type="number"
                                                className="border rounded   p-[2px]"
                                            />
                                        </td>
                                        <td className="px-2">
                                            <button className="whitespace-nowrap bg-green-600 text-white font-semibold p-1 rounded">
                                                Full Settle
                                            </button>
                                        </td>
                                        <td className=" p-2">
                                            <input
                                                className="border rounded  p-[2px] text-xs"
                                                placeholder="Remark"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w-screen  p-1 rounded overflow-x-auto  md:w-full">
                            <table className=" border-collapse border text-xs">
                                <thead className="bg-[#007082] text-white font-bold">
                                    <tr>
                                        <th className="border p-2">Account</th>
                                        <th className="border p-2">
                                            Client(P/L)
                                        </th>
                                        <th className="border p-2 " colSpan="2">
                                            Settle Amount
                                        </th>
                                        <th className="border p-2 text-center">
                                            Remarks
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white">
                                        <td className="border p-2">
                                            radheuser99
                                        </td>
                                        <td className="border p-2">User2</td>
                                        <td className="px-2">
                                            <input
                                                placeholder="0"
                                                type="number"
                                                className="border rounded   p-[2px]"
                                            />
                                        </td>
                                        <td className="px-2">
                                            <button className="whitespace-nowrap bg-green-600 text-white font-semibold p-1 rounded">
                                                Full Settle
                                            </button>
                                        </td>
                                        <td className=" p-2">
                                            <input
                                                className="border rounded  p-[2px] text-xs"
                                                placeholder="Remark"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* second content */}
                    <div className="flex items-center justify-center absolute bottom-0 bg-[#ccc] w-full py-2 md:py-4">
                        <div className="flex flex-col items-start gap-2 md:flex-row">
                            <div className="flex items-center justify-center gap-1">
                                <input
                                    placeholder="Remarks"
                                    className="p-[2px] md:py-[5px] rounded px-2"
                                />
                                <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-4 mx-1   md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                    Fill All
                                </button>
                            </div>
                            <div className="flex items-center justify-center gap-1">
                                <input
                                    placeholder="Password"
                                    className="p-[2px] md:py-[5px] rounded px-2"
                                />
                                <button className=" bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-semibold md:font-bold py-2 md:py-2 md:px-8 px-1    md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                    Submit
                                </button>
                                <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-semibold md:font-bold py-2 md:py-2 md:px-8 px-1    md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                    Clear All
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default User;
