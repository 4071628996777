import React from "react";

const CasinoAnalysis = () => {
    // Example dynamic data for live casino and virtual casino
    const liveCasinoData = [
        {
            "Event Name": "Live Teenpatti",
            "Total Order": "0",
            Exposure: "0",
            "Total Amount": "0",
            "Max Profit": "0",
        },
        {
            "Event Name": "Live Blackjack",
            "Total Order": "10",
            Exposure: "100",
            "Total Amount": "500",
            "Max Profit": "50",
        },
        {
            "Event Name": "Live Blackjack",
            "Total Order": "10",
            Exposure: "100",
            "Total Amount": "500",
            "Max Profit": "50",
        },
        {
            "Event Name": "Live Blackjack",
            "Total Order": "10",
            Exposure: "100",
            "Total Amount": "500",
            "Max Profit": "50",
        },
        {
            "Event Name": "Live Blackjack",
            "Total Order": "10",
            Exposure: "100",
            "Total Amount": "500",
            "Max Profit": "50",
        },
        {
            "Event Name": "Live Blackjack",
            "Total Order": "10",
            Exposure: "100",
            "Total Amount": "500",
            "Max Profit": "50",
        },
        {
            "Event Name": "Live Blackjack",
            "Total Order": "10",
            Exposure: "100",
            "Total Amount": "500",
            "Max Profit": "50",
        },
    ];

    const virtualCasinoData = [
        {
            "Event Name": "Virtual Roulette",
            "Total Order": "5",
            Exposure: "50",
            "Total Amount": "250",
            "Max Profit": "25",
        },
        {
            "Event Name": "Virtual Slots",
            "Total Order": "15",
            Exposure: "150",
            "Total Amount": "750",
            "Max Profit": "75",
        },
        {
            "Event Name": "Virtual Slots",
            "Total Order": "15",
            Exposure: "150",
            "Total Amount": "750",
            "Max Profit": "75",
        },
        {
            "Event Name": "Virtual Slots",
            "Total Order": "15",
            Exposure: "150",
            "Total Amount": "750",
            "Max Profit": "75",
        },
        {
            "Event Name": "Virtual Slots",
            "Total Order": "15",
            Exposure: "150",
            "Total Amount": "750",
            "Max Profit": "75",
        },
        {
            "Event Name": "Virtual Slots",
            "Total Order": "15",
            Exposure: "150",
            "Total Amount": "750",
            "Max Profit": "75",
        },
    ];

    return (
        <div className="overflow-hidden  bg-gray-200">
            <div className="p-4">
                <div className="text-[#16b0c8] text-[16px] font-[700] px-2 py-2 bg-white">
                    Indian Poker Analysis
                </div>
                {/* Live Casino */}
                <div className="mb-8 bg-white shadow-md rounded border-[#159eb6] border-2">
                    <div className="bg-[#159eb6] text-white text-md font-semibold py-1 px-4">
                        Live Casino
                    </div>
                    <div className="  w-screen md:w-full overflow-x-auto p-[5px] md:p-2">
                        <table className="md:w-full divide-y divide-gray-200 overflow-x-auto">
                            <thead className="bg-[#717171] text-white">
                                <tr>
                                    <th className="px-6 py-1 text-left text-xs font-bold uppercase border-r-2 border-gray-400">
                                        Event Name
                                    </th>
                                    <th className="px-6 py-1 text-left text-xs font-bold whitespace-nowrap uppercase border-r-2 border-gray-400">
                                        Total Order
                                    </th>
                                    <th className="px-6 py-1 text-left text-xs  font-bold uppercase border-r-2 border-gray-400">
                                        Exposure
                                    </th>
                                    <th className="px-6 py-1 text-left text-xs font-bold whitespace-nowrap uppercase border-r-2 border-gray-400">
                                        Total Amount
                                    </th>
                                    <th className="px-6 py-1 text-left text-xs font-bold whitespace-nowrap uppercase border-r-2 border-gray-400">
                                        Max Profit
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {liveCasinoData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-1 whitespace-nowrap  text-sm font-bold text-[#007082] underline cursor-pointer border">
                                            {item["Event Name"]}
                                        </td>
                                        <td className="px-6 py-1  text-sm text-black text-right font-semibold border border-gray-300">
                                            {item["Total Order"]}
                                        </td>
                                        <td className="px-6 py-1  text-sm text-green-400 text-right font-semibold border">
                                            {item["Exposure"]}
                                        </td>
                                        <td className="px-6 py-1  text-sm text-black text-right font-semibold border">
                                            {item["Total Amount"]}
                                        </td>
                                        <td className="px-6 py-1  text-sm text-black text-right font-semibold border">
                                            {item["Max Profit"]}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Virtual Casino */}
                <div className="mb-8 bg-white shadow-md rounded border-[#159eb6] border-2">
                    <div className="bg-[#159eb6] text-white text-md font-semibold py-1 px-4">
                        Virtual Casino
                    </div>
                    <div className="  w-screen md:w-full overflow-x-auto p-[5px] md:p-2">
                        <table className="md:w-full divide-y divide-gray-200 overflow-x-auto">
                            <thead className="bg-[#717171] text-white">
                                <tr>
                                    <th className="px-6 py-1 text-left text-xs font-bold whitespace-nowrap uppercase border-r-2 border-gray-400 border">
                                        Event Name
                                    </th>
                                    <th className="px-6 py-1 text-left text-xs font-bold  uppercase border-r-2 border-gray-400 border">
                                        Total Order
                                    </th>
                                    <th className="px-6 py-1 text-left text-xs font-bold uppercase border-r-2 border-gray-400 border">
                                        Exposure
                                    </th>
                                    <th className="px-6 py-1 text-left text-xs font-bold whitespace-nowrap uppercase border-r-2 border-gray-400 border">
                                        Total Amount
                                    </th>
                                    <th className="px-6 py-1 text-left text-xs font-bold whitespace-nowrap uppercase border-r-2 border-gray-400 border">
                                        Max Profit
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {virtualCasinoData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-1 whitespace-nowrap text-sm font-bold text-[#007082] underline cursor-pointer border">
                                            {item["Event Name"]}
                                        </td>
                                        <td className="px-6 py-1  text-sm text-black text-right font-semibold border border-gray-300">
                                            {item["Total Order"]}
                                        </td>
                                        <td className="px-6 py-1  text-sm text-green-400 text-right font-semibold border">
                                            {item["Exposure"]}
                                        </td>
                                        <td className="px-6 py-1  text-sm text-black text-right font-semibold border">
                                            {item["Total Amount"]}
                                        </td>
                                        <td className="px-6 py-1  text-sm text-black text-right font-semibold border">
                                            {item["Max Profit"]}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CasinoAnalysis;
