import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import Accordion from "./../../../acordion/Acordion"; // Adjust this import path as per your project structure

const Sidebar = ({ isSidebarOpen, isSmallScreen, allTabs, sidebarTabs }) => {
    const [openTabs, setOpenTabs] = useState({});

    const toggleDropdown = (index) => {
        setOpenTabs((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const accordionData = {
        Cricket: [
            {
                title: "Indian Premier League Virtual T0",
                children: [
                    {
                        title: "Lucknow Super Giants VR T10 v Gujarat Titans Vr T10",
                    },
                ],
            },
            {
                title: "Chalotte Edwards Cup",
                children: [
                    {
                        title: "North West Thunder v Southern Vipers",
                    },
                    {
                        title: "Central Sparks V Northern Diamonds",
                    },
                ],
            },
        ],
        Soccer: [
            {
                title: "Friendlies International",
                children: [
                    { title: "Scotland V Finland" },
                    { title: "Malta V Czech Republic" },
                ],
            },
        ],
        Tennis: [
            {
                title: "Men's Roland Garros 2023",
                children: [
                    { title: "Carlos Alcaraz V Sinner" },
                    { title: "Ruud V A Zverev" },
                ],
            },
        ],
        Horse: [
            {
                title: "GB-Goodwood",
                children: [{ title: "Goodwood 7th Jun" }],
            },
            {
                title: "FR-Compiegne",
                children: [{ title: "Compiegne (FRA) 7th Jun" }],
            },
        ],
        Greyhound: [
            {
                title: "GB-Towecester",
                children: [{ title: "Towcester 7th Jun" }],
            },
            {
                title: "GB-Newcastle",
                children: [{ title: "Newcastle 7th Jun" }],
            },
        ],
        "Settlement User": [
            {
                title: "User",
                children: [
                    { title: "User Subtab 1" },
                    { title: "User Subtab 2" },
                ],
            },
        ],
        "Settlement Master": [
            {
                title: "Master",
                children: [
                    { title: "Master Subtab 1" },
                    { title: "Master Subtab 2" },
                ],
            },
        ],
    };

    const toggleSubTab = (mainTabIndex, subTabIndex) => {
        setOpenTabs((prev) => ({
            ...prev,
            [mainTabIndex]: {
                ...prev[mainTabIndex],
                [subTabIndex]: !prev[mainTabIndex]?.[subTabIndex],
            },
        }));
    };

    return (
        <aside
            className={`bg-[#146578]  pb-[4rem] w-[48%] lg:w-[15%] fixed top-12 lg:top-16 left-0  z-50 bg-sidebar-gradient transition-transform duration-500 ${
                isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            }`}
            style={{
                backgroundImage: "linear-gradient(#146578, #4ebbcd)",
                backgroundColor: "rgba(20, 101, 120, 1)",
                height: "100vh",
                overflowY: "auto",
            }}
        >
            {(isSmallScreen ? [...allTabs, ...sidebarTabs] : sidebarTabs).map(
                (tab, id) => (
                    <div key={id}>
                        <div
                            className="flex items-center justify-between px-2 cursor-pointer hover:bg-[#1a98ae]"
                            onClick={() =>
                                tab.hasDropdown && toggleDropdown(id)
                            }
                        >
                            <div className="flex items-center gap-2 px-2 py-1">
                                <img
                                    className="w-[1rem]"
                                    src={tab.icon}
                                    alt={tab.label}
                                />
                                <Link
                                    to={tab.path}
                                    className="whitespace-nowrap text-white text-sm font-semibold py-1 "
                                >
                                    {tab.label}
                                </Link>
                            </div>
                            {tab.hasDropdown && (
                                <div>
                                    {openTabs[id] ? (
                                        <FaCaretUp className="text-white" />
                                    ) : (
                                        <FaCaretDown className="text-white" />
                                    )}
                                </div>
                            )}
                        </div>
                        {tab.hasDropdown && openTabs[id] && (
                            <div className="">
                                {tab.subTabs &&
                                    tab.subTabs.map((subTab, subId) => (
                                        <div key={subId} className="ml-4">
                                            <div
                                                className="flex items-center gap-2 px-2 py-1 cursor-pointer hover:bg-[#1a98ae]"
                                                onClick={() =>
                                                    toggleSubTab(id, subId)
                                                }
                                            >
                                                <Link
                                                    to={subTab.path}
                                                    className="whitespace-nowrap text-white text-sm font-semibold py-1"
                                                >
                                                    {subTab.label}
                                                </Link>
                                            </div>
                                            {/* Render sub-tab content based on openTabs state */}
                                            {openTabs[id]?.[subId] &&
                                                accordionData[subTab.label] && (
                                                    <div className="ml-4">
                                                        <Accordion
                                                            data={
                                                                accordionData[
                                                                    subTab.label
                                                                ]
                                                            }
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                            </div>
                        )}
                        <hr />
                    </div>
                )
            )}
        </aside>
    );
};

export default Sidebar;
