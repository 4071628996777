import React, { useState } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
// import "./App.css"; // Import custom CSS for transitions

const CasinoLock = () => {
    const [expend, setExpend] = useState(true);
    const handleexpend = () => setExpend(!expend);
    const games = [
        "7 up & 7 down",
        "Live Teenpatti",
        "Teenpatti T20",
        "Dragon Tiger",
        "Hi Low",
        "Worli Matka",
        "Poker",
        "32 cards casino",
        "Andar Bahar",
        "Amar Akbar Anthony",
        "Race 20-20",
        "Bollywood Casino",
        "Six player poker",
        "Roulette",
        "Sic-bo",
        "Mufflis Teenpatti",
        "Trio",
        "Baccarat",
        "Casino Meter",
        "Poker 20-20",
        "2 Card Teenpatti",
        "Queen Race",
        "Teenpatti Test",
        "Casino War",
        "The Trap",
        "Teenpatti Open",
        "29 Card Baccarat",
        "Race to 17",
    ];

    return (
        <div className="w-full">
            <div className="bg-white m-2 p-2 ">
                <div className="relative p-4">
                    <div className="flex items-center my-[-10px]">
                        <div className="border-t border-[#16b0c8] pl-4 "></div>
                        <div className="text-[#16b0c8] text-[14px] font-bold bg-white px-2 ml-[0px] ">
                            Provider:
                        </div>
                        <div className="flex-grow border-t border-[#16b0c8] "></div>
                    </div>
                    <div className="border-l border-r border-b border-[#16b0c8] rounded-b p-4 ">
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Indian Poker/ Live Casino
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Indian Poker II
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Evolution
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">Vivo</div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">WAC</div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Betgames
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Casino III
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Spribe
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pl-8">
                    <div className="text-[#16b0c8] text-sm font-bold py-2">
                        Casino Control
                    </div>

                    <div className="px-2 flex items-center gap-4">
                        <div
                            onClick={handleexpend}
                            className="cursor-pointer font-extrabold text-black "
                        >
                            {expend ? (
                                <CiSquarePlus className="text-xl" />
                            ) : (
                                <CiSquareMinus className="text-xl" />
                            )}
                        </div>
                        <div className="text-xs  text-[#16b0c8]  font-bold">
                            Indian Poker
                        </div>
                    </div>
                    <div
                        className={`pl-12 text-xs font-medium my-2 transition-all duration-500 ${
                            expend ? "slide-in-left" : "slide-out-left"
                        }`}
                    >
                        <ul className="flex flex-col gap-2 text-[#16b0c8] text-xs font-bold">
                            {games.map((game, index) => (
                                <li key={index}>{game}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CasinoLock;
