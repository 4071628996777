import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import { BiSolidDownArrow } from "react-icons/bi";
import UserInformation from "./../../userInformation/UserInformation";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdArrowRightAlt } from "react-icons/md";
const Header = ({ isSmallScreen, allTabs, toggleSidebar }) => {
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = (index) => {
        setOpenDropdownIndex(index);
    };

    const handleMouseLeave = () => {
        setOpenDropdownIndex(null);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    return (
        <>
            <header className="fixed top-0 z-40 h-12 lg:h-16 bg-[#18b0c8] text-white p-2 bg-gradient-to-b flex items-center justify-between w-full">
                {/* hamburger */}
                <div className="flex items-center gap-1 lg:gap-2 ">
                    <div onClick={toggleSidebar}>
                        <GiHamburgerMenu className="text-[#a1aed4] lg:text-[1.3rem] cursor-pointer" />
                    </div>
                    <div>
                        <Link to="/dashboard">
                            <img
                                className="w-auto md:w-[20rem] lg:h-[3rem] cursor-pointer"
                                src="https://images.staticcontent.io/rde/aimg/admin-logo.svg"
                                alt="logo"
                            />
                        </Link>
                    </div>
                </div>
                {/* tabs */}
                <div className="flex text-xs gap-1 text-center w-full mx-4">
                    {!isSmallScreen &&
                        allTabs.map((tab, id) => (
                            <div
                                key={id}
                                className="relative"
                                onMouseEnter={() =>
                                    tab.hasDropdown && handleMouseEnter(id)
                                }
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link to={tab.path}>
                                    <div className="whitespace-nowrap text-sm font-[500] flex items-center gap-1 px-1">
                                        {tab.label}
                                        <div>
                                            {tab.hasDropdown && (
                                                <BiSolidDownArrow className="w-2" />
                                            )}
                                        </div>
                                    </div>
                                </Link>
                                {tab.hasDropdown && (
                                    <div
                                        className={`absolute left-0 mt-[1.3rem] w-[16rem] bg-[#16a4bc]  rounded-md shadow-lg z-10 transition-opacity ${
                                            openDropdownIndex === id
                                                ? "opacity-100 visible"
                                                : "opacity-0 invisible"
                                        }`}
                                        onMouseEnter={() =>
                                            handleMouseEnter(id)
                                        }
                                        onMouseLeave={handleMouseLeave}
                                        style={{
                                            transition:
                                                "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out",
                                        }}
                                    >
                                        <div className="py-2">
                                            {tab.subTabs.map(
                                                (subTab, subId) => (
                                                    <Link
                                                        key={subId}
                                                        to={subTab.path}
                                                    >
                                                        <div className="px-4 py-2 text-[16px] text-start text-semibold  hover:bg-[#67c6db] flex items-center gap-1 text-white cursor-pointer  overflow-hidden w-full">
                                                            {/* <FaLongArrowAltRight className="text-md font-extrabold" /> */}
                                                            <div>
                                                                {subTab.label}
                                                            </div>
                                                        </div>
                                                        <hr></hr>
                                                    </Link>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>

                {/* user dropdown */}
                <div
                    className="relative flex items-center lg:justify-end cursor-pointer"
                    onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                >
                    <div>admin99radhe</div>
                    <div>
                        <FaCaretDown />
                    </div>
                    {isUserDropdownOpen && (
                        <div className="absolute right-0 top-9 md:top-9 lg:top-11  md:w-44 lg:w-48 bg-white rounded-md shadow-lg z-10">
                            <Link to="changepassword">
                                <div className="flex items-center px-4 py-1 text-sm md:text-[14px] font-medium text-gray-800 hover:bg-[#17a2b8] hover:text-white cursor-pointer">
                                    <MdArrowRightAlt className="text-xl" />
                                    Change Password
                                </div>
                            </Link>
                            <div
                                className=" flex items-center px-4 py-1 text-sm md:text-[14px] font-medium whitespace-nowrap text-gray-800 hover:bg-[#17a2b8] hover:text-white cursor-pointer"
                                onClick={handleLogout}
                            >
                                <MdArrowRightAlt className="text-xl" />
                                Logout
                            </div>
                        </div>
                    )}
                </div>
            </header>
            <UserInformation />
        </>
    );
};

export default Header;
