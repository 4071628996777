import React from "react";

const SportsRevenue = () => {
    return (
        <div className="">
            <div className=" bg-white overflow-hidden ">
                {/* searching fields */}
                <div className="overflow-hidden">
                    <div>Sports Revenue</div>
                    <div className="flex items-center gap-4 flex-wrap">
                        <input
                            type="datetime-local"
                            id="datetime"
                            className="w-[15rem] p-1 border rounded"
                        />
                        <input
                            type="datetime-local"
                            id="datetime"
                            className="w-[15rem] p-1 border rounded"
                        />
                        <div className="flex">
                            <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1  mt-3 md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                Go
                            </button>
                            <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1  mt-3 md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>

                <div className="my-4 flex items-end justify-between">
                    {/* search */}
                    <div className="flex items-center gap-2">
                        <input
                            type="text"
                            placeholder="Search"
                            className="p-1 border rounded"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/csv-icon.svg"
                            alt="csv icon"
                            title="Export Data"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/csv-icon.svg"
                            alt="csv icon"
                            title="Export Data"
                        />
                    </div>

                    {/* entries  */}
                    <div className="flex items-center gap-2">
                        <div>Show</div>
                        <select className="border rounded p-1">
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                        </select>
                        <div>entries</div>
                    </div>
                </div>

                {/* table */}
                <div className="w-screen border p-4 rounded overflow-x-auto">
                    <table className=" border-collapse border text-xs">
                        <thead className="bg-[#007082] text-white font-bold">
                            <tr>
                                <th className="border p-2"></th>
                                <th className="border p-2"></th>
                                <th className="border p-2"></th>
                                <th
                                    colSpan="7"
                                    className="border p-2 text-center"
                                >
                                    Cricket
                                </th>
                                <th
                                    colSpan="7"
                                    className="border p-2 text-center"
                                >
                                    Soccer
                                </th>
                                <th
                                    colSpan="7"
                                    className="border p-2 text-center"
                                >
                                    Tennis
                                </th>
                                <th
                                    colSpan="4"
                                    className="border p-2 text-center"
                                >
                                    Horse
                                </th>
                                <th
                                    colSpan="4"
                                    className="border p-2 text-center"
                                >
                                    Greyhound
                                </th>
                                <th
                                    colSpan="5"
                                    className="border p-2 text-center"
                                >
                                    Kabaddi
                                </th>
                                <th className="border p-2"></th>
                                <th className="border p-2"></th>
                            </tr>
                            <tr>
                                <th className="border p-2">User</th>
                                <th className="border p-2">Distributor</th>
                                {/* <th className="border p-2"></th> */}
                                <th className="border p-2">MATCH ODDS</th>
                                <th className="border p-2">BOOKMAKER</th>
                                <th className="border p-2">FANCY</th>
                                <th className="border p-2">LINE</th>
                                <th className="border p-2">LOTTERY</th>
                                <th className="border p-2">Commission</th>
                                <th className="border p-2">Total</th>
                                <th className="border p-2">Bets</th>
                                <th className="border p-2">MATCH ODDS</th>
                                <th className="border p-2">BOOKMAKER</th>
                                <th className="border p-2">OVER UNDER</th>
                                <th className="border p-2">LOTTERY</th>
                                <th className="border p-2">Commission</th>
                                <th className="border p-2">Total</th>
                                <th className="border p-2">Bets</th>
                                <th className="border p-2">MATCH ODDS</th>
                                <th className="border p-2">SET WINNER</th>
                                <th className="border p-2">BOOKMAKER</th>
                                <th className="border p-2">LOTTERY</th>
                                <th className="border p-2">Commission</th>
                                <th className="border p-2">Total</th>
                                <th className="border p-2">Bets</th>
                                <th className="border p-2">WIN</th>
                                <th className="border p-2">Commission</th>
                                <th className="border p-2">Total</th>
                                <th className="border p-2">Bets</th>
                                <th className="border p-2">WIN</th>
                                <th className="border p-2">Commission</th>
                                <th className="border p-2">Total</th>
                                <th className="border p-2">Bets</th>
                                <th className="border p-2">BOOKMAKER</th>
                                <th className="border p-2">FANCY</th>
                                <th className="border p-2">LOTTERY</th>
                                <th className="border p-2">Total</th>
                                <th className="border p-2">Bets</th>
                                <th className="border p-2">Total P&L</th>
                                <th className="border p-2">My share↑↓</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-gray-200">
                                <td className="border p-2">user</td>
                                {/* <td className="border p-2">User1</td> */}
                                <td className="border p-2">Distributor1</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">-13</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">-13.00</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">0.00</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                <td className="border p-2">0</td>
                                {/* <td className="border p-2">0</td> */}
                            </tr>
                            <tr className="bg-white">
                                {/* <td className="border p-2"></td> */}
                                <td className="border p-2">User2</td>
                                <td className="border p-2">Distributor2</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">-14</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">-14.10</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">0.10</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                <td className="border p-2">1</td>
                                {/* <td className="border p-2">1</td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SportsRevenue;
