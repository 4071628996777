import React, { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";

const ChangePassword = () => {
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);

    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    const toggleShowPassword3 = () => {
        setShowPassword3(!showPassword3);
    };

    return (
        <div className="m-2 ">
            <div className="bg-white p-4">
                <div className="mb-4 mt-4 w-full md:w-[20rem]">
                    <label className="block text-sm font-medium text-gray-700">
                        Old Password:
                    </label>
                    <div className="relative mt-1">
                        <input
                            type={showPassword1 ? "text" : "password"}
                            className="border p-2 rounded w-full pr-10"
                        />
                        <button
                            type="button"
                            onClick={toggleShowPassword1}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                        >
                            {showPassword1 ? <LuEye /> : <LuEyeOff />}
                        </button>
                    </div>
                </div>
                <div className="mb-4 mt-4 w-full md:w-[20rem]">
                    <label className="block text-sm font-medium text-gray-700">
                        New Password:
                    </label>
                    <div className="relative mt-1">
                        <input
                            type={showPassword2 ? "text" : "password"}
                            className="border p-2 rounded w-full pr-10"
                        />
                        <button
                            type="button"
                            onClick={toggleShowPassword2}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                        >
                            {showPassword2 ? <LuEye /> : <LuEyeOff />}
                        </button>
                    </div>
                </div>
                <div className="mb-4 mt-4 w-full md:w-[20rem]">
                    <label className="block text-sm font-medium text-gray-700">
                        Retype Password:
                    </label>
                    <div className="relative mt-1">
                        <input
                            type={showPassword3 ? "text" : "password"}
                            className="border p-2 rounded w-full pr-10"
                        />
                        <button
                            type="button"
                            onClick={toggleShowPassword3}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                        >
                            {showPassword3 ? <LuEye /> : <LuEyeOff />}
                        </button>
                    </div>
                </div>
                <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1  mt-3 md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                    Change Password
                </button>
            </div>
        </div>
    );
};

export default ChangePassword;
