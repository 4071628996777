import React, { useState } from "react";

const AgentMaster = () => {
    const [activeButton, setActiveButton] = useState("1");

    const buttonClasses = (button) =>
        `px-4 py-1 ${
            activeButton === button
                ? "text-white bg-[#11859c] bg-gradient-to-b from-[#11859c] to-[#181818] border-[#72bbef] text-xs md:text-sm"
                : "bg-[#88d6e3] border-[#72bbef] hover:bg-buttonHover text-xs ms:text-sm"
        }`;

    return (
        <div className="">
            <div className="bg-white overflow-hidden p-4">
                {/* Searching fields */}
                <div className="overflow-hidden">
                    <div>Add Agent Details</div>
                    <div className="flex items-center gap-4 flex-wrap">
                        <input
                            type="text"
                            id="text"
                            placeholder="Add Name"
                            className="w-[12rem] text-[16px] text-black p-1 border border-[#e6dada] rounded"
                        />
                        <input
                            type="text-local"
                            placeholder="Add Mobile Number"
                            className="w-[12rem] text-[16px] text-black p-1 border border-[#e6dada] rounded"
                        />
                        <input
                            type="text-local"
                            id="text"
                            placeholder="Add Reference Name"
                            className="w-[12rem] text-[16px] text-black p-1 border border-[#e6dada] rounded"
                        />
                        <input
                            type="text-local"
                            id="text"
                            placeholder="Remark"
                            className="w-[12rem] text-[16px] text-black p-1 border border-[#e6dada] rounded"
                        />
                        <div className="flex">
                            <button className="bg-[#97cfd9] hover:bg-[#6eb0bc] whitespace-nowrap text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1 mt-3 md:mt-0 rounded-md cursor-pointer">
                                Submit
                            </button>
                            <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowrap text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1 mt-3 md:mt-0 rounded-md cursor-pointer">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>

                {/* Table */}
                <div className="border p-2 rounded">
                    <table className="w-full border-collapse border">
                        <thead>
                            <tr className="bg-[#007082] text-white text-sm text-left">
                                <th className="border p-2">Name</th>
                                <th className="border p-2"> Mobile Number</th>
                                <th className="border p-2"> Refrence Name</th>
                                <th className="border p-2">Remarks</th>
                                <th className="border p-2">Is Active</th>
                            </tr>
                        </thead>
                        <tbody className="text-xs font-medium">
                            <tr className="bg-gray-200 text-left">
                                <td className="border p-2">
                                    lucifer morning start
                                </td>
                                <td className="border p-2">012345689</td>
                                <td className="border p-2">Detective Cloi</td>
                                <td className="border p-2">Agent</td>
                                <td className="border p-2">True</td>
                            </tr>
                        </tbody>
                    </table>

                    {/* Footer */}
                    <div className="flex flex-col  md:flex-row justify-between items-center mt-4 w-full md:px-4">
                        <div className="text-sm mb-2 md:mb-0">
                            Showing 1 to 4 of 5 entries
                        </div>
                        <div className="flex">
                            {["First", "Prev", "1", "Next", "Last"].map(
                                (button, index) => (
                                    <button
                                        key={index}
                                        className={buttonClasses(button)}
                                        onClick={() => setActiveButton(button)}
                                    >
                                        {button}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgentMaster;
