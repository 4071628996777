import React, { useState } from "react";

const TransactionReport = () => {
    const [activeButton, setActiveButton] = useState("1");
    const [dateTime, setDateTime] = useState("");

    const handleChange = (event) => {
        setDateTime(event.target.value);
    };
    const buttonClasses = (button) =>
        `px-4 py-1 ${
            activeButton === button
                ? "text-white bg-[#11859c] bg-gradient-to-b from-[#11859c] to-[#181818] border-[#72bbef] text-xs md:text-sm"
                : "bg-[#88d6e3] border-[#72bbef] hover:bg-buttonHover text-xs ms:text-sm"
        }`;

    return (
        <div>
            <div className="m-2 bg-white p-4">
                {/* searching fields */}
                <div className="">
                    <div>Account Statement Report</div>
                    <div className="flex items-center gap-4">
                        <select className="w-[15rem] p-1 border px-1">
                            <option>All</option>
                            <option>BALANCE REPORT</option>
                            <option>GAME REPORT</option>
                            <option>SETTLEMENT REPORT</option>
                            <option>BONUS REPORT</option>
                        </select>
                        <select className="w-[15rem] p-1 border px-1">
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                            <option>All</option>
                        </select>
                        <input
                            type="text"
                            placeholder="Search by Client"
                            className="w-[15rem] p-1 border rounded"
                        />
                        <input
                            type="datetime-local"
                            id="datetime"
                            value={dateTime}
                            onChange={handleChange}
                            className="w-[15rem] p-1 border rounded"
                        />
                        <input
                            type="datetime-local"
                            id="datetime"
                            value={dateTime}
                            onChange={handleChange}
                            className="w-[15rem] p-1 border rounded"
                        />
                        <div className="flex">
                            <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1  mt-3 md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                Go
                            </button>
                            <button className="bg-[#5ecbdd] hover:bg-buttonHover whitespace-nowarp text-xs text-center md:text-xs text-white font-bold py-2 md:py-2 md:px-8 px-8 mx-1  mt-3 md:mt-0 rounded-md bg-button cursor-pointer whitespace-nowrap">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>

                <div className="my-4 flex items-end justify-between">
                    {/* search */}
                    <div className="flex items-center gap-2">
                        <input
                            type="text"
                            placeholder="Search"
                            className="p-1 border rounded"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/csv-icon.svg"
                            alt="csv icon"
                            title="Export Data"
                        />
                        <img
                            className="w-[25px] md:w-[35px] cursor-pointer"
                            src="https://images.staticcontent.io/casino/banner/images/pdf-icon.svg"
                            alt="pdf icon"
                            title="Export Data"
                        />
                    </div>

                    {/* balance table */}
                    <div className="flex flex-col">
                        <table className="w-full text-sm text-center ">
                            {/* <thead>
                                <tr>
                                    <th className="border p-1">Sport</th>
                                    <th className="border p-1">Sport</th>
                                </tr>
                            </thead> */}
                            <tbody>
                                <tr className="">
                                    <td className="border-2 p-1 px-[10rem]">
                                        Opening Balance
                                    </td>
                                    <td className="border-2 p-1 px-[5rem]">
                                        100
                                    </td>
                                </tr>
                                <tr className="">
                                    <td className="border-2 p-1">
                                        Closing Balance{" "}
                                    </td>
                                    <td className="border-2 p-1">100</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* entries  */}
                    <div className="flex items-center gap-2">
                        <div>Show</div>
                        <select>
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                        </select>
                        <div>entries</div>
                    </div>
                </div>

                {/* table */}
                <div className="border p-2 rounded">
                    <table className="w-full border-collapse border">
                        <thead>
                            <tr className="bg-[#007082] text-white text-sm text-left">
                                <th className="border p-1">Date & Time</th>
                                <th className="border p-1">Credit</th>
                                <th className="border p-1">Debit</th>
                                <th className="border p-1">Closing</th>
                                <th className="border p-1">Description</th>
                                <th className="border p-1">From → To</th>
                            </tr>
                        </thead>
                        <tbody className="text-[14px] font-normal">
                            <tr className="bg-gray-200">
                                <td className="border p-2">
                                    01/01/2024 10:00 AM
                                </td>
                                <td className="border p-2">100</td>
                                <td className="border p-2">50</td>
                                <td className="border p-2">50</td>
                                <td className="border p-2">Initial Deposit</td>
                                <td className="border p-2">
                                    radheuser99 → admin99radhe
                                </td>
                            </tr>
                            <tr className="bg-white">
                                <td className="border p-2">
                                    02/01/2024 12:00 PM
                                </td>
                                <td className="border p-2">200</td>
                                <td className="border p-2">100</td>
                                <td className="border p-2">150</td>
                                <td className="border p-2">Payment Received</td>
                                <td className="border p-2">
                                    radheuser99 → admin99radhe
                                </td>
                            </tr>
                            <tr className="bg-gray-200">
                                <td className="border p-2">
                                    03/01/2024 02:00 PM
                                </td>
                                <td className="border p-2">150</td>
                                <td className="border p-2">75</td>
                                <td className="border p-2">125</td>
                                <td className="border p-2">Purchase</td>
                                <td className="border p-2">
                                    radheuser99 → admin99radhe
                                </td>
                            </tr>
                            <tr className="bg-white">
                                <td className="border p-2">
                                    04/01/2024 04:00 PM
                                </td>
                                <td className="border p-2">$300</td>
                                <td className="border p-2">$150</td>
                                <td className="border p-2">$175</td>
                                <td className="border p-2">Refund</td>
                                <td className="border p-2">
                                    radheuser99 → admin99radhe
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {/* Footer */}
                    <div className="flex flex-col  md:flex-row justify-between items-center mt-4 w-full md:px-4">
                        <div className="text-sm mb-2 md:mb-0">
                            Showing 1 to 4 of 5 entries
                        </div>
                        <div className="flex">
                            {["First", "Prev", "1", "Next", "Last"].map(
                                (button, index) => (
                                    <button
                                        key={index}
                                        className={buttonClasses(button)}
                                        onClick={() => setActiveButton(button)}
                                    >
                                        {button}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionReport;
