import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
const GameLock = () => {
    const [events, setEvents] = useState([
        {
            "event-name": "Cricket",
            events: [
                "Match Odds",
                "Bookmaker",
                "Special Maker",
                "Tournament Winner",
                "Tied Match",
                "Completed Match",
                "To Win the Toss",
                "1st Inning Runs",
                "1st Inning 6 Ovr Line",
                "1st Inning 10 Ovr Line",
                "1st Inning 15 Ovr Line",
                "1st Inning 20 Ovr Line",
                "2nd inning Runs",
                "2nd Inning 6 Ovr Line",
                "2nd Inning 10 Ovr Line",
                "2nd Inning 15 Ovr Line",
                "Over",
                "Batsman",
                "Single Over",
                "Odd Even",
                "Three Selection",
                "Ball By Ball",
                "Lottery",
            ],
        },
        {
            "event-name": "Soccer",
            events: [
                "Match Odds",
                "Bookmaker",
                "Special Maker",
                "Over Under 05",
                "Over Under 15",
                "Over Under 25",
                "Over Under 35",
                "Lottery",
            ],
        },
        {
            "event-name": "Tennis",
            events: [
                "Match Odds",
                "Set Winner",
                "Bookmaker",
                "Special Maker",
                "Lottery",
            ],
        },
        {
            "event-name": "Kabaddi",
            events: ["Bookmaker", "Overs", "Overs", "Lottery"],
        },
        {
            "event-name": "Horse",
            events: ["Win"],
        },
        {
            "event-name": "GreyHound",
            events: ["Win"],
        },
    ]);

    const [match, setMatch] = useState([
        {
            "event-name": "Cricket",
            events: [
                {
                    matchId: "12597512",
                    matchName: "Men's Wimbledon 2024",
                    subEvents: [
                        "33389486 - Houston Dynamo v Los Angeles FC",
                        "1.230356748 - Match Odds",
                        "1.230356752 - Over/Under 0.5 Goals",
                        "1.230356758 - Over/Under 2.5 Goals",
                        "1.230356762 - Over/Under 1.5 Goals",
                    ],
                    expanded: false,
                },
                {
                    matchId: "12597518",
                    matchName: "Women's Wimbledon 2024",
                    subEvents: [
                        "33389510 - San Jose Earthquakes v Chicago Fire",
                        "1.230356640 - Match Odds",
                        "1.230356644 - Over/Under 0.5 Goals",
                        "1.230356650 - Over/Under 2.5 Goals",
                        "1.230356654 - Over/Under 1.5 Goals",
                    ],
                    expanded: false,
                },
            ],
        },
        {
            "event-name": "Soccer",
            events: [
                {
                    matchId: "141",
                    matchName: "US Major League Soccer",
                    subEvents: [
                        "1.230356748 - Match Odds",
                        "1.230356752 - Over/Under 0.5 Goals",
                        "1.230356758 - Over/Under 2.5 Goals",
                        "1.230356762 - Over/Under 1.5 Goals",
                    ],
                    expanded: false,
                },
            ],
        },
        {
            "event-name": "Tennis",
            events: [
                {
                    matchId: "12671439",
                    matchName: "WTA Contrexeville 2024",
                    subEvents: [
                        "1.230356748 - Match Odds",
                        "1.230356752 - Over/Under 0.5 Goals",
                        "1.230356758 - Over/Under 2.5 Goals",
                        "1.230356762 - Over/Under 1.5 Goals",
                    ],
                    expanded: false,
                },
            ],
        },
    ]);

    const handleToggle = (eventName, matchIndex) => {
        const updatedMatch = match.map((item) => ({
            ...item,
            events:
                item["event-name"] === eventName
                    ? item.events.map((event, index) => ({
                          ...event,
                          expanded:
                              index === matchIndex ? !event.expanded : false,
                      }))
                    : item.events,
        }));

        setMatch(updatedMatch);
    };
    const [eventName, setEventName] = useState("Cricket");
    return (
        <div className="w-full">
            <div className="bg-white m-2 p-2 ">
                {/* user search section */}
                <div className="px-4">
                    <div className="text-[#16b0c8] text-[16px] font-bold ">
                        Lock Application - admin99radhe
                    </div>
                    <input
                        type="text"
                        placeholder="Search by client"
                        className="border-2 p-[px] pl-2 rounded-md w-[15rem]"
                    />
                </div>

                {/* conditional rendering section */}

                <div className="relative p-4">
                    {/* Event Type: */}
                    <div className="flex items-center my-[-10px]">
                        <div className="border-t border-[#16b0c8] pl-4 "></div>
                        <div className="text-[#16b0c8] text-[14px] font-bold bg-white px-2 ml-[0px] ">
                            Event Type:
                        </div>
                        <div className="flex-grow border-t border-[#16b0c8] "></div>
                    </div>
                    <div className="border-l border-r border-b border-[#16b0c8] rounded-b p-4 ">
                        <div className="flex items-center gap-4">
                            {/* cricket */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Cricket
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Soccer */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Soccer
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Tennis */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Tennis
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Kabaddi */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Kabaddi
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Horse */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">Horse</div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Greyhound */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Greyhound
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Type */}
                <div className="relative p-4">
                    {/* Type: */}
                    <div className="flex items-center my-[-10px]">
                        <div className="border-t border-[#16b0c8] pl-4 "></div>
                        <div className="text-[#16b0c8] text-[14px] font-bold bg-white px-2 ml-[0px] ">
                            Type:
                        </div>
                        <div className="flex-grow border-t border-[#16b0c8] "></div>
                    </div>
                    <div className="border-l border-r border-b border-[#16b0c8] rounded-b p-4 ">
                        <div className="flex items-center gap-4">
                            {/* All Odds */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    All Odds
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Exch Bookmaker */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Exch Bookmaker
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* All Bookmaker */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    All Bookmaker
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Betfair Fancy */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Betfair Fancy
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Exch Fancy */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Exch Fancy
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                            {/* Other Fancy */}
                            <div className="flex items-center gap-2">
                                <div className="text-sm font-medium">
                                    Other Fancy
                                </div>
                                <input
                                    type="checkbox"
                                    className="w-[15px] h-[15px]"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Market Type: */}
                <div className="relative  ">
                    {/* Market Type: */}
                    <div className="flex items-center my-[-10px]">
                        <div className="border-t border-[#16b0c8] pl-4 "></div>
                        <div className="text-[#16b0c8] text-[14px] font-bold bg-white px-2 ml-[0px] ">
                            Market Type:
                        </div>
                        <div className="flex-grow border-t border-[#16b0c8] "></div>
                    </div>
                    <div className="border-l border-r border-b border-[#16b0c8] rounded-b p-4 ">
                        <div className="flex flex-col items-start gap-4">
                            {/* cricket */}
                            <div className="">
                                <div className="text-[#16b0c8] font-medium">
                                    Cricket
                                </div>
                                <div className="flex flex-col flex-wrap gap-4 mt-2">
                                    {events.map((sport, sportIndex) => (
                                        <div key={sportIndex} className="mb-2">
                                            <div className="text-[#16b0c8] font-medium">
                                                {sport["event-name"]}
                                            </div>
                                            <div className="flex flex-wrap gap-4 mt-2">
                                                {sport.events.map(
                                                    (event, eventIndex) => (
                                                        <div
                                                            key={eventIndex}
                                                            className="flex items-center gap-1"
                                                        >
                                                            <div className="text-sm font-medium">
                                                                {event}
                                                            </div>
                                                            <input
                                                                type="checkbox"
                                                                className="w-[15px] h-[15px]"
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between px-4 ">
                        <div className="text-[#16b0c8] font-semibold">
                            Competition / Event / Markets
                        </div>
                        <select className="border-2 w-[15rem] p-1 rounded">
                            <option>July 1, 2024</option>
                            <option>July 2, 2024</option>
                            <option>July 3, 2024</option>
                            <option>July 4, 2024</option>
                            <option>July 5, 2024</option>
                            <option>July 6, 2024</option>
                            <option>July 7, 2024</option>
                            <option>July 8, 2024</option>
                        </select>
                    </div>
                </div>

                <div className="w-full">
                    <div className="">
                        {/* Displaying event types */}
                        <div className="relative ">
                            <div className=" rounded-b p-1 px-4 ">
                                <div className="flex items-center gap-4">
                                    {match.map((event, eventIndex) => (
                                        <div
                                            key={eventIndex}
                                            className="flex items-center gap-2 cursor-pointer"
                                            onClick={() =>
                                                setEventName(
                                                    event["event-name"]
                                                )
                                            }
                                        >
                                            <div className="text-sm font-medium">
                                                {event["event-name"]}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Event matches */}
                        {eventName && (
                            <div className="relative ">
                                <div className="rounded ">
                                    {match
                                        .filter(
                                            (item) =>
                                                item["event-name"] === eventName
                                        )
                                        .map((filteredEvent, index) => (
                                            <div key={index} className="">
                                                {filteredEvent.events.map(
                                                    (
                                                        matchEvent,
                                                        matchIndex
                                                    ) => (
                                                        <div
                                                            key={matchIndex}
                                                            className="px-4"
                                                        >
                                                            <div
                                                                className="font-medium cursor-pointer bg-[#16b0c8] text-white text-sm  w-full my-2 p-1"
                                                                onClick={() =>
                                                                    handleToggle(
                                                                        eventName,
                                                                        matchIndex
                                                                    )
                                                                }
                                                            >
                                                                <div className="px-2 flex items-center justify-between">
                                                                    <div>
                                                                        {matchEvent.matchId +
                                                                            " - " +
                                                                            matchEvent.matchName}
                                                                    </div>
                                                                    <div className="">
                                                                        {matchEvent.expanded ? (
                                                                            <FaMinus />
                                                                        ) : (
                                                                            <FaPlus />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {matchEvent.expanded && (
                                                                <ul className="pl-4  px-4  ">
                                                                    {matchEvent.subEvents.map(
                                                                        (
                                                                            subEvent,
                                                                            subIndex
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    subIndex
                                                                                }
                                                                            >
                                                                                {
                                                                                    subEvent
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameLock;
